<template>
  <box v-if="item && item.id" class="ticket-viewer">
    <div slot="header">
      <div style="width: 20%; display: inline-block">
        <span>{{ item.title }} [#{{ item.ticket_id }}]</span>
      </div>
      <div style="width: 70%; display: inline-block">
        <div style="width: 20%; display: inline-block">
          <div class="columns is-vcentered">
            <span class="lable-inline">{{ $t('status') }}: </span>
            <span class="select">
              <select
                v-model="item.status" :class="{
                  'select-ticket-setting': true,
                  'color-red': item.status === 'Overdue',
                  'color-yellow': item.status === 'Pending',
                  'color-blue': item.status === 'Unassigned',
                  'color-green': item.status === 'Closed'
                }" @change="changeSelect"
              >
                <option class="is-color-red" :selected="item.status === 'Overdue'" value="Overdue">
                  {{ $t('overdue') }}
                </option>
                <option class="is-color-yellow" :selected="item.status === 'Pending'" value="Pending">
                  {{ $t('pending') }}
                </option>
                <option
                  class="is-color-blue" :selected="item.status === 'Unassigned'"
                  value="Unassigned"
                >
                  {{ $t('unassigned') }}
                </option>
                <option class="is-color-green" :selected="item.status === 'Closed'" value="Closed">
                  {{ $t('closed') }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <div style="width: 20%; display: inline-block">
          <div class="columns is-vcentered">
            <span class="lable-inline">{{ $t('priority') }}: </span>
            <span class="select">
              <select
                v-model="item.priority" :class="{
                  'select-ticket-setting': true,
                  'color-red': item.priority === 'Critical',
                  'color-yellow': item.priority === 'High',
                  'color-blue': item.priority === 'Medium',
                  'color-green': item.priority === 'Low'
                }" @change="changeSelect"
              >
                <option
                  class="is-color-red" :selected="item.priority === 'Critical'"
                  value="Critical"
                >
                  {{ $t('critical') }}
                </option>
                <option class="is-color-yellow" :selected="item.priority === 'High'" value="High">
                  {{ $t('high') }}
                </option>
                <option class="is-color-blue" :selected="item.priority === 'Medium'" value="Medium">
                  {{ $t('medium') }}
                </option>
                <option class="is-color-green" :selected="item.priority === 'Low'" value="Low">
                  {{ $t('low') }}
                </option>
              </select>
            </span>
          </div>
        </div>
        <span>{{ $t('actions') }}:</span>
        <button class="button is-text" :disabled="item.status === 'Closed'" @click.prevent="addNote">
          <octicon :icon="note" /> <span>{{ $t('addNote') }}</span>
        </button>
        <button v-if="item.status === 'Closed'" class="button is-text" @click.prevent="reopenTicket">
          <octicon :icon="issueReopened" /> <span>{{ $t('reopen') }}</span>
        </button>
        <button v-else class="button is-text" @click.prevent="closeTicket">
          <octicon :icon="issueClosed" /> <span>{{ $t('close') }}</span>
        </button>
        <button class="button is-text" @click.prevent="destroy">
          <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
        </button>
      </div>
    </div>
    <div class="ticket-content">
      <div class="message reply-data">
        <div class="message-header">
          <p>
            {{ getName(item.creator.email) }}
            {{ $t('created_ticket_massage') }} {{ getNames(item.maintainer) }}
          </p>
        </div>
      </div>
      <div v-for="(itm, i) in item.ticket_data" :key="i">
        <div class="message">
          <div class="message description-data">
            <div class="message-header">
              <p>
                {{ getName(itm.from) }}
                <span v-if="itm.processor && itm.processor.length === 0">
                  {{ $t('add_note_massage') }}</span><span v-else>
                  {{ $t('assigned_to') }} {{ getNames(itm.processor) }}</span>
                {{ $t('at') }} {{ new Date(itm.update_time).toLocaleString() }}
              </p>
              <div style="float: right">
                <button class="button is-text" @click="forwardNote(itm)">
                  <octicon :icon="arrowRight" />
                  <span>{{ $t('forward') }}</span>
                </button>
                <button class="button is-text" @click="showReply(i)">
                  <octicon :icon="reply" /> <span>{{ $t('reply') }}</span>
                </button>
                <button
                  class="button is-text" :disabled="itm.from !== user._id"
                  @click.prevent="deleteNote(i)"
                >
                  <octicon :icon="trashcan" /> <span>{{ $t('delete') }}</span>
                </button>
              </div>
            </div>
            <div class="message-content">
              <p>{{ itm.content }}</p>
            </div>
            <div v-if="itm.attach_files && itm.attach_files.length > 0" class="message-attachment">
              <div v-for="(file, j) in itm.attach_files" :key="j">
                <button
                  v-if="file.length > 12" class="button" :title="$t('download_message')"
                  @click="downloadFile(file)"
                >
                  <octicon :icon="download" />
                  <span>{{ file.substring(12, file.length) }}</span>
                </button>
              </div>
            </div>
          </div>
          <template v-if="itm.processor && itm.processor.length > 0">
            <template v-if="item && itm.reply_data && itm.reply_data.length > 0">
              <div v-for="(rep, ri) in itm.reply_data" :key="ri" class="message reply-data">
                <div class="message-header">
                  <p>
                    {{ getName(rep.processor) }} {{ $t('replied') }}
                    {{ $t('at') }}
                    {{ new Date(itm.update_time).toLocaleString() }}
                  </p>
                </div>
                <div class="message-content">
                  <p>{{ rep.reply_data }}</p>
                </div>
              </div>
            </template>
            <div v-if="pendingUser(itm).length > 0" class="message reply-data">
              <div class="message-header">
                <p>
                  {{ getNames(pendingUser(itm)) }} {{ $t('pending_message') }}
                </p>
              </div>
            </div>
          </template>
          <div
            v-if="newReplyContent[i] && newReplyContent[i].show" :ref="'endNote' + i"
            class="message reply-data reply_message"
          >
            <div class="message-header">
              <p>{{ $t('replyTo') }} {{ getName(itm.from) }}</p>
            </div>
            <vue-editor v-model="newReplyContent[i].reply_data" :editor-toolbar="customToolbar" />
            <div class="add-note-btn">
              <file-select v-model="newReplyContent[i].attach_files" />
              <div class="btn-item" />
              <button class="button is-warning btn-item" @click="newReplyContent[i].show = false">
                {{ $t('hide') }}
              </button>
              <button class="button is-info btn-item" @click="replyContent(i)">
                {{ $t('reply') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.status === 'Closed'" class="message reply-data">
        <div class="message-header">
          <p>
            {{ getName(item.closed_user) }} {{ $t('closed_message') }}
            {{ new Date(item.last_update).toLocaleString() }}
          </p>
        </div>
      </div>
      <div>
        <div v-if="addingNote" class="add-note">
          <div class="add-note-assign">
            <div class="add-note-input">
              <div class="group-field">
                <div class="field-auto" style="width: -webkit-fill-available">
                  <label class="label">{{ $t('assignedTo') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <v-select
                        v-model="notecontent.processor" :options="assignedList"
                        :multiple="true" :taggable="true" :push-tags="true"
                      />
                    </p>
                  </div>
                </div>
                <div class="field-auto" style="margin-top: 32px; width: fit-content; height: 40px">
                  <button
                    id="deadline-btn" class="button" type="button"
                    :class="notecontent.remind ? 'is-success' : 'is-default'" title="Set Deadline"
                    style="height: 40px" @click="notecontent.remind = !notecontent.remind"
                  >
                    <octicon :icon="clock" :scale="1.5" /><br><span />
                  </button>
                </div>
              </div>
              <div v-show="notecontent.remind" class="field-line" style="margin-top: -4px">
                <div class="field-2">
                  <label class="label">{{ $t('deadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <date-picker :value.sync="notecontent.deadline" time required />
                    </p>
                  </div>
                </div>
                <div class="field-2">
                  <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <span class="select" style="width: 100%">
                        <select v-model="notecontent.offset" style="width: 100%">
                          <option selected="true" :value="15">15 Mins</option>
                          <option :value="30">30 Mins</option>
                          <option :value="60">1 Hour</option>
                          <option :value="180">3 Hours</option>
                          <option :value="360">6 Hours</option>
                          <option :value="720">12 Hours</option>
                          <option :value="1440">1 Day</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vue-editor v-model="notecontent.content" :editor-toolbar="customToolbar" />
          <div ref="add-note" class="add-note-btn">
            <file-select v-model="notecontent.attach_files" />
            <button class="button is-danger btn-item" @click="cancelNote">
              {{ $t('cancel') }}
            </button>
            <button class="button is-succes btn-item" @click="addNoteContent">
              {{ $t('addNote') }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <div v-if="isforwarding" class="add-note">
          <div class="add-note-assign">
            <div class="add-note-input">
              <div class="group-field">
                <div class="field-auto" style="width: -webkit-fill-available">
                  <label class="label">{{ $t('assignedTo') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <v-select
                        v-model="notecontent.processor" :options="assignedList"
                        :multiple="true" :taggable="true" :push-tags="true"
                      />
                    </p>
                  </div>
                </div>
                <div class="field-auto" style="margin-top: 32px; width: fit-content; height: 40px">
                  <button
                    id="deadline-btn" class="button" type="button"
                    :class="notecontent.remind ? 'is-success' : 'is-default'" title="Set Deadline"
                    style="height: 40px" @click="notecontent.remind = !notecontent.remind"
                  >
                    <octicon :icon="clock" :scale="1.5" /><br><span />
                  </button>
                </div>
              </div>
              <div v-show="notecontent.remind" class="field-line" style="margin-top: -4px">
                <div class="field-2">
                  <label class="label">{{ $t('deadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <date-picker :value.sync="notecontent.deadline" time required />
                    </p>
                  </div>
                </div>
                <div class="field-2">
                  <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                  <div class="field is-grouped">
                    <p class="control is-expanded">
                      <span class="select" style="width: 100%">
                        <select v-model="notecontent.offset" style="width: 100%">
                          <option selected="true" :value="15">15 Mins</option>
                          <option :value="30">30 Mins</option>
                          <option :value="60">1 Hour</option>
                          <option :value="180">3 Hours</option>
                          <option :value="360">6 Hours</option>
                          <option :value="720">12 Hours</option>
                          <option :value="1440">1 Day</option>
                        </select>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vue-editor v-model="notecontent.content" :editor-toolbar="customToolbar" />
          <div ref="add-note" class="add-note-btn">
            <file-select v-model="notecontent.attach_files" />
            <button class="button is-danger btn-item" @click="cancelNote">
              {{ $t('cancel') }}
            </button>
            <button class="button is-succes btn-item" @click="addNoteContent">
              {{ $t('forward') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ref="endPage" />
    <div class="update-btn" :class="hasUpdate ? 'show' : 'hide'">
      <button id="sync-btn" class="button is-primary" @click="reloadTicket">
        <octicon :icon="sync" :scale="3" /><br><span />
      </button>
    </div>
  </box>
</template>
<script>
import FileSelect from '@/components/FileSelect'
import { DatePicker } from '@cyradar/ui'
import {
  arrowRight,
  clock,
  desktopDownload,
  issueClosed,
  issueReopened,
  note,
  pencil,
  plus,
  reply,
  sync,
  trashcan
} from 'octicons-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { VueEditor } from 'vue2-editor'

export default {
  components: { VueEditor, FileSelect, vSelect, DatePicker },
  props: {
    ticketId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      item: {},
      UpdateItem: {},
      hasUpdate: false,
      assignedBuff: '',
      isforwarding: false,
      notecontent: {
        processor: [],
        content: '',
        deadline: new Date(),
        offset: 30,
        remind: false,
        attach_files: []
      },
      newReplyContent: [],
      userNames: [],
      formData: new FormData(),
      addingNote: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block']
      ]
    }
  },
  computed: {
    trashcan () {
      return trashcan
    },
    sync () {
      return sync
    },
    clock () {
      return clock
    },
    download () {
      return desktopDownload
    },
    issueClosed () {
      return issueClosed
    },
    reply () {
      return reply
    },
    issueReopened () {
      return issueReopened
    },
    note () {
      return note
    },
    arrowRight () {
      return arrowRight
    },
    pencil () {
      return pencil
    },
    plus () {
      return plus
    },
    user () {
      return this.$store.getters['authentication/user']
    },
    assignedList () {
      return this.userNames.map((c) => c.email)
    }
  },
  mounted () {
    this.ticketData()
    this.loadUserName()
    setInterval(() => {
      this.checkUpdate()
    }, 15000)
  },
  methods: {
    forwardNote (itm) {
      this.addingNote = false
      this.isforwarding = true
      this.notecontent.content = itm.content
      this.notecontent.attach_files = itm.attach_files
      setTimeout(() => {
        this.$refs.endPage.scrollIntoView()
      }, 100)
    },
    showReply (i) {
      this.newReplyContent[i].show = true
      setTimeout(() => {
        var ref = 'endNote' + i
        this.$refs[ref][0].scrollIntoView()
      }, 100)
    },
    myReply (itm) {
      var pending = this.pendingUser(itm)
      return (
        itm.processor &&
        itm.processor.length > 0 &&
        itm.processor.includes(this.user._id) &&
        pending.includes(this.user._id)
      )
    },
    pendingUser (itm) {
      var p = []
      if (!itm.reply_data || itm.reply_data.length === 0) {
        return itm.processor
      }
      itm.processor.map((c) => {
        var flag = true
        itm.reply_data.map((d) => {
          if (d.processor === c) {
            flag = false
            return
          }
          if (flag) {
            p.push(c)
          }
        })
      })
      return p
    },
    checkIsprocessor (processors, email) {
      return processors.includes(email)
    },
    getNames (emails) {
      if (!emails || !this.user) {
        return this.$t('Unknow')
      }
      var newUsrs = []
      emails.map((c) => {
        if (c === this.user._id) {
          newUsrs.push(this.$t('you'))
        } else {
          var flag = true
          this.userNames.map((d) => {
            if (d.email === c) {
              newUsrs.push(d.name)
              flag = false
            }
          })
          if (flag) {
            newUsrs.push(c)
          }
        }
      })
      return newUsrs.join(',')
    },
    getName (email) {
      if (!email || !this.user) {
        return this.$t('Unknow')
      }

      if (email === this.user._id) {
        return this.$t('you')
      }
      var usr = ''
      this.userNames.map((c) => {
        if (c.email === email) {
          usr = c.name
        }
      })

      return usr
    },
    updateTicket () {
      if (this.item && this.item.id) {
        return this.$http
          .patch(`/api/v1/tickets/${this.item.id}`, this.item)
          .then((body) => {
            if (!body || !body.data) {
              return
            }

            this.$store.dispatch('NOTIFY', {
              type: 'success',
              text: body.data.message
            })

            this.addingNote = false
            this.isforwarding = false
            this.ticketData()
          })
      }
    },
    changeSelect () {
      this.updateTicket()
    },
    closeTicket () {
      if (!window.confirm(this.$t('rus'))) {
        return
      }
      this.item.status = 'Closed'
      this.item.closed_user = this.user._id
      this.item.last_update = new Date()
      this.updateTicket()
    },
    reopenTicket () {
      if (!window.confirm(this.$t('rus'))) {
        return
      }
      this.item.status = 'Unassigned'
      this.item.last_update = new Date()
      this.updateTicket()
    },
    addNote () {
      this.addingNote = true
      setTimeout(() => {
        this.$refs.endPage.scrollIntoView()
      }, 100)
    },
    cancelNote () {
      this.addingNote = false
    },
    deleteNote (index) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      if (index >= this.item.ticket_data.length) {
        return
      }
      var buff = []
      for (var i = 0; i < this.item.ticket_data.length; i++) {
        if (i !== index) {
          buff.push(this.item.ticket_data[i])
        }
      }
      this.item.ticket_data = buff
      this.updateTicket()
    },
    addNoteContent () {
      var buffitem = this.item
      buffitem.ticket_data.push({
        from: this.user._id,
        processor: this.notecontent.processor,
        content: this.notecontent.content
      })

      this.formData = new FormData()
      this.formData.append('data', JSON.stringify(buffitem))
      for (var i = 0; i < this.notecontent.attach_files.length; i++) {
        this.formData.append('attachfiles', this.notecontent.attach_files[i])
      }

      this.updateTicketData(buffitem.ticket_data.length - 1, 0, 0)
    },
    updateTicketData (index, isReply, replyIndex) {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
      return this.$http
        .patch(
          `/api/v1/tickets/data/${this.item.id}/${index}/${isReply}/${replyIndex}`,
          this.formData,
          { headers }
        )
        .then((body) => {
          if (!body || !body.data) {
            return
          }

          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: body.data.message
          })
          this.addingNote = false
          this.isforwarding = false
          this.ticketData()
          this.notecontent = {
            processor: [],
            content: '',
            deadline: new Date(),
            offset: 30,
            remind: false,
            attach_files: []
          }
        })
    },
    replyContent (id) {
      this.newReplyContent[id].processor = this.user._id
      this.newReplyContent[id].update_time = new Date()
      this.item.ticket_data[id].reply_data.push(this.newReplyContent[id])
      this.updateTicket()
    },
    downloadFile (file) {
      return this.$http
        .get(`/api/v1/download/${file}`, { responseType: 'blob' })
        .then((body) => {
          if (!body || !body.data) {
            return []
          }
          const url = window.URL.createObjectURL(new Blob([body.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file.substring(12, file.length))
          document.body.appendChild(link)
          link.click()
        })
    },
    loadUserName () {
      return this.$http
        .get('/api/v1/users')
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          return body.data.data
        })
        .then((users) => {
          this.userNames = users.items
        })
    },
    destroy () {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http
        .delete(`/api/v1/tickets/${this.item.id}`)
        .then((body) => {
          if (!body || !body.data) {
            return
          }

          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: body.data.message
          })

          setTimeout(function () {
            window.location.href = '/#/tickets'
          }, 1000)
        })
    },
    ticketData () {
      return this.$http
        .get('/api/v1/tickets', {
          params: {
            ticketid: this.ticketId,
            filter: '',
            sort: '_id',
            order: 'asc',
            start: 0,
            end: new Date().getTime(),
            page: 1,
            limit: 1
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return {}
          }
          if (
            !body.data.data ||
            !body.data.data.items ||
            body.data.data.total === 0
          ) {
            this.$store.dispatch('NOTIFY', {
              type: 'error',
              text: 'Cannot find ticket ID. Redirect to Home'
            })
            setTimeout(function () {
              window.location.href = '/#/tickets'
            }, 2000)
          }
          this.item = body.data.data.items[0]
          this.loadReplyArray(this.item.ticket_data)
        })
    },
    checkUpdate () {
      return this.$http
        .get('/api/v1/tickets', {
          params: {
            ticketid: this.ticketId,
            filter: '',
            sort: '_id',
            order: 'asc',
            start: 0,
            end: new Date().getTime(),
            page: 1,
            limit: 1
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return {}
          }
          if (
            !body.data.data ||
            !body.data.data.items ||
            body.data.data.total === 0
          ) {
            this.$store.dispatch('NOTIFY', {
              type: 'error',
              text: 'Ticket ID Not Found.'
            })
          }
          this.UpdateItem = body.data.data.items[0]
          if (this.UpdateItem.last_update !== this.item.last_update) {
            if (!this.hasUpdate) {
              this.$store.dispatch('NOTIFY', {
                type: 'success',
                text: this.$t('ticketUpdateNoti')
              })
            }
            this.hasUpdate = true
          }
        })
    },
    reloadTicket () {
      this.hasUpdate = false
      this.item = this.UpdateItem
      this.$store.dispatch('NOTIFY', {
        type: 'success',
        text: this.$t('ticketUpdateNotiSuccess')
      })
    },
    loadReplyArray (data) {
      if (!data) {
        return
      }
      this.newReplyContent = []
      for (var i = 0; i < data.length; i++) {
        this.newReplyContent.push({
          attach_files: [],
          reply_data: '',
          processor: '',
          show: false,
          update_time: new Date()
        })
      }
    },
    validateEmail (email) {
      const re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
</script>
<style lang="scss">
#sync-btn {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  padding: 0 0 0 0.35rem;
  -webkit-animation: cog 2s infinite;
  -moz-animation: cog 2s infinite;
  -ms-animation: cog 2s infinite;
  animation: cog 2s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes cog {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.update-btn {
  position: fixed;
  z-index: 100;
}

.update-btn.show {
  left: 50%;
  bottom: 60px;
  -webkit-animation: showup 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: showup 1s;
  /* Firefox < 16 */
  -ms-animation: showup 1s;
  /* Internet Explorer */
  -o-animation: showup 1s;
  /* Opera < 12.1 */
  animation: showup 1s;
}

.update-btn.hide {
  bottom: -60px;
}

@keyframes showup {
  from {
    bottom: -60px;
  }

  to {
    bottom: 60px;
  }
}

.ticket-viewer {}

.color-red {
  color: #e75555 !important;
}

.color-green {
  color: #3da200 !important;
}

.color-yellow {
  color: #e08400 !important;
}

.color-blue {
  color: #3583ff !important;
}

.message {
  .dashboard.is-dark & {
    background-color: #424652;
    border: 1px solid #4e525d;
  }

  border: 1px solid #ccc;
  background-color: #f0f1fa;
  border-radius: 5px;
  padding: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.reply-data {
  .dashboard.is-dark & {
    background-color: #4d6079 !important;
    border: 1px solid #8e8e8e !important;
  }
}

.message-header {
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
  font-style: italic;
  min-height: 40px;
}

.message-header p {
  width: fit-content;
  float: left;
}

.reply_message {
  transition: width 600ms ease-out, height 600ms ease-out;
}

.message-content {
  margin: 0 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid #8e8e8e;
}

.message-content * {
  color: inherit !important;
  background-color: inherit !important;
  background: inherit !important;
}

.message-attachment button {
  margin-bottom: 0.5rem;
  background-color: unset !important;
  border-color: #d0d0d0 !important;
}

.message-attachment button:hover {
  background-color: #4a4a4a !important;
  border-color: #d0d0d0 !important;
}

.description-data {
  border: 1px solid #8e8e8e !important;
}

.ql-fill {
  .dashboard.is-dark & {
    fill: #aaa !important;
  }
}

.quillWrapper .ql-snow .ql-stroke {
  .dashboard.is-dark & {
    stroke: #aaa !important;
  }
}

.quillWrapper {
  border-radius: 5px;
}

.ql-container.ql-snow {
  border: 1px solid #aaa !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.file-select {
  width: 80%;
}

.file-select>.select-button {
  width: fit-content;
  font-size: 1rem;
  border-radius: 5px;
  display: inline-block;
  font-weight: unset !important;

  .dashboard.is-dark & {
    background: unset !important;
    color: #dddddf !important;
    border: 1px solid #ccc;
  }
}

.add-note {
  .dashboard.is-dark & {
    background-color: #424652;
    border: 1px solid #4e525d;
  }

  border: 1px solid #ccc;
  background-color: #f0f1fa;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.add-note-btn {
  margin-top: 0.5rem;
  display: flex;
}

.btn-item {
  width: 10%;
  margin: 0 0.5rem;
  display: inline-block;
}

.add-note-assign {
  display: flex;
}

.add-note-input {
  width: -webkit-fill-available;
  display: inline-flex;
}

.add-note-input .v-select {
  width: 100%;
  height: 40px;
  display: inline-block;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.add-note-input .v-select input {
  border: unset !important;
}

.add-note-assign button {
  border-radius: unset !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  display: inline-block;
  width: 50px;
}

.vs__dropdown-toggle {
  height: 100%;
}

.select-ticket-setting {
  background-color: unset !important;
  border: unset !important;
}
</style>
