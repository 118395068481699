<template>
  <label class="file-select">
    <div class="select-button">
      <span v-if="value.length > 0">Attachments: {{ displayFiles }}</span>
      <span v-else>Add Attachments</span>
    </div>
    <input type="file" multiple="true" @change="handleFileChange">
  </label>
</template>
<script>
export default {
  props: {
    value: Array
  },
  computed: {
    displayFiles () {
      var names = []
      this.value.map((file) => {
        names.push(file.name)
      })
      var namesString = names.join()
      return namesString.length > 50
        ? namesString.substring(0, 47) + '...'
        : namesString
    }
  },
  methods: {
    handleFileChange (e) {
      this.$emit('input', Array.from(e.target.files))
    }
  }
}
</script>
<style scoped>
.file-select > .select-button {
  padding: 0.3rem;
  color: #2a2d36;
  background-color: #f0f0f0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.file-select > input[type='file'] {
  display: none;
}
</style>
