<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.actions') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.actions') }}
        </div>
        <div class="level-right">
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /><span>{{ $t('newAction') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box>
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column field="title" :label="$t('title')" width="20%" />
        <data-column field="description" :label="$t('description')" />
        <data-column field="method" :label="$t('method')" width="10%" />
        <data-column field="url" :label="$t('url')" />
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <modal v-if="item" :show.sync="modal">
      <box>
        <div slot="header">
          {{ item.title || $t('newAction') }}
        </div>

        <form @submit.prevent="submit">
          <template v-if="!expanded">
            <div class="field">
              <label class="label">{{ $t('title') }}</label>
              <div class="control">
                <input
                  v-model="item.title" class="input" type="text"
                  required
                >
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('description') }}</label>
              <div class="control">
                <input v-model="item.description" class="input" type="text">
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('method') }}</label>
              <div class="field">
                <p class="control">
                  <span class="select is-fullwidth">
                    <select v-model="item.method" required>
                      <option disabled value="">{{ $t('selectDefault') }}</option>
                      <option v-for="e in verbs" :key="e" :value="e">{{ e }}</option>
                    </select>
                  </span>
                </p>
              </div>
            </div>

            <div class="field">
              <label class="label">{{ $t('url') }}</label>
              <div class="control">
                <input
                  v-model="item.url" class="input" type="text"
                  required
                >
              </div>
            </div>
          </template>

          <div class="has-text-centered has-text-grey-light">
            <p>
              <a href="#" class="button is-text" @click.prevent="expanded = !expanded">
                <octicon v-if="expanded" :icon="chevronUp" /><octicon v-else :icon="chevronDown" /> <span v-text="expanded ? $t('details') : $t('headersAndPayload')" />
              </a>
            </p>
          </div>

          <tabs v-show="expanded" :theme="$_ui_theme_tabs">
            <tab title="Headers" style="padding-left: 0;padding-right: 0;">
              <div class="field">
                <div class="field is-grouped">
                  <p class="control is-expanded">
                    <input
                      v-model="header.key" class="input is-small" type="text"
                      :placeholder="$t('key')"
                    >
                  </p>
                  <p class="control is-expanded">
                    <input
                      v-model="header.value" class="input is-small" type="text"
                      :placeholder="$t('value')"
                    >
                  </p>
                  <p class="control">
                    <a class="button is-info is-small" style="width: 4em" @click.prevent="addHeader">{{ $t('add') }}</a>
                  </p>
                </div>
              </div>

              <hr>

              <div v-for="(h, index) in item.headers" :key="index" class="field">
                <div class="field is-grouped">
                  <p class="control is-expanded">
                    <input
                      v-model="h.key" class="input is-small" type="text"
                      :placeholder="$t('key')"
                    >
                  </p>
                  <p class="control is-expanded">
                    <input
                      v-model="h.value" class="input is-small" type="text"
                      :placeholder="$t('value')"
                    >
                  </p>
                  <p class="control">
                    <a class="button is-danger is-small" style="width: 4em" @click.prevent="removeHeader(index)"><octicon :icon="x" /></a>
                  </p>
                </div>
              </div>
            </tab>
            <tab title="Payload" style="padding-left: 0;padding-right: 0;">
              <div :class="{'api-fields': true, 'is-editing': editing > -1}">
                <api-channel-field :field="field" @add="addField" />
                <hr>
                <api-channel-field
                  v-for="(f, index) in item.fields" :key="index"
                  :field="f" :editing="editing === index" readonly
                  @edit="editing = index" @remove="removeField(index)" @cancel="cancelField($event, index)"
                />
              </div>
            </tab>
          </tabs>

          <div slot="footer" class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button is-link">
                {{ $t('save') }}
              </button>
            </div>
            <div class="control">
              <button type="button" class="button is-link is-light" @click.prevent="closeModal">
                {{ $t('cancel') }}
              </button>
            </div>
          </div>
        </form>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal, Tabs, Tab } from '@cyradar/ui'
import { plus, x, pencil, chevronUp, chevronDown } from 'octicons-vue'
import APIChannelField from '@/components/APIChannelField'

const verbs = ['head', 'get', 'post', 'put', 'patch', 'delete']
const defaultFieldValue = () => ({
  name: '',
  is_number: false,
  derived: false,
  fixed_value: '',
  definition: {
    src_field: '',
    is_mapping: false,
    mapping_values: []
  },
  is_timestamp: false,
  source_format: '',
  output_format: '',
  max_length: 0
})

export default {
  components: { DataTable, DataColumn, Modal, Tabs, Tab, 'api-channel-field': APIChannelField },
  data () {
    return {
      modal: false,
      item: null,
      header: {
        key: '',
        value: ''
      },
      field: defaultFieldValue(),
      expanded: false,
      editing: -1
    }
  },
  computed: {
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    chevronDown () {
      return chevronDown
    },
    chevronUp () {
      return chevronUp
    },
    verbs () {
      return verbs.map(x => x.toUpperCase())
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    submit () {
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    create () {
      this.item = {
        method: ''
      }

      this.modal = true
      this.expanded = false
    },
    edit (item) {
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
      this.expanded = false
    },
    addHeader () {
      if (!this.item.headers) {
        this.item.headers = []
      }

      if (!this.header.key) {
        return
      }

      this.item.headers.push(this.header)
      this.header = {}
    },
    removeHeader (idx) {
      if (!this.item.headers) {
        this.item.headers = []
      }

      this.item.headers.splice(idx, 1)
    },
    addField () {
      if (!this.item.fields) {
        this.item.fields = []
      }

      this.item.fields.push(this.field)
      this.field = defaultFieldValue()
    },
    cancelField ($event, index) {
      this.editing = -1
      this.$set(this.item.fields, index, $event)
    },
    removeField (idx) {
      if (!this.item.fields) {
        this.item.fields = []
      }

      this.item.fields.splice(idx, 1)
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/api-channels', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    store () {
      return this.$http.post('/api/v1/api-channels', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/api-channels/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/api-channels/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
<style lang="scss">
$x: #1f2128;
.control.has-icons-right {
  &.has-two-icons {
    .input{
      padding-right: 5em;
    }

    .icon.is-right.is-first {
      right: 2.5em;
    }
  }

  &.has-three-icons {
    .input {
      padding-right: 7.5em;
    }

    .icon.is-right.is-first {
      right: 5em;
    }

    .icon.is-right.is-second {
      right: 2.5em;
    }
  }

  .input {
    &:focus~.icon {
      color: auto;
    }
  }

  .icon {
    pointer-events: auto;
    cursor: pointer;
    color: #dbdbdb;
    padding: 0 .25em;

    .dashboard.is-dark & {
      color: #404553;
      &.is-primary {
        color: #fff!important;
        fill: #fff!important;
      }
    }

    &.is-primary {
      color: #555!important;
      fill: #555!important;
    }
  }
}

.api-fields {
  &.is-editing .api-field.is-readonly {
    transition: opacity linear 0.3s;
    &:not(.is-editing) {
      opacity: .5;
    }
  }
}

.api-field {
  &.is-readonly {
    padding: .5em 0;
    border-bottom: 1px solid $x;
  }
}
</style>
