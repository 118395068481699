<template>
  <div>
    <div class="status-head">
      <div class="incidents-status">
        <standard-chart
          v-if="ready"
          ref="chart"
          :viz-data="vizData.solved"
          color="blue"
        />
      </div>
      <div class="incidents-status">
        <standard-chart
          v-if="ready"
          ref="chart"
          :viz-data="vizData.inprocess"
          color="yellow"
        />
      </div>
      <div class="incidents-status">
        <standard-chart
          v-if="ready"
          ref="chart"
          :viz-data="vizData.falsePositive"
          color="red"
        />
      </div>
      <div class="incidents-status">
        <standard-chart v-if="ready" ref="chart" :viz-data="vizData.total" />
      </div>
    </div>
    <div class="incidents-seconds">
      <div class="incidents-timeline">
        <standard-chart
          v-if="timeLineChartready"
          ref="chart"
          :viz-data="timeLineChart"
          :show-title="false"
        />
      </div>
      <div class="incidents-sla">
        <standard-chart
          v-if="ready"
          ref="chart"
          :viz-data="sla"
          color="green"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StandardChart from '@/components/StandardChart'

export default {
  components: { StandardChart },
  props: {
    range: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      ready: true,
      sla: {
        title: 'slaTitle',
        type: 'sum',
        total: 1,
        unit: 'Min'
      },
      timeLineChartready: false,
      vizData: {
        solved: {
          title: 'solved',
          type: 'sum',
          total: 0
        },
        inprocess: {
          title: 'inProcess',
          type: 'sum',
          total: 0
        },
        falsePositive: {
          title: 'falsePositive',
          type: 'sum',
          total: 0
        },
        total: {
          title: 'total',
          type: 'sum',
          total: 0
        }
      },
      timeLineChart: {}
    }
  },
  watch: {
    range () {
      if (this.range.length < 2) {
        return
      }
      this.loadPage()
    }
  },
  methods: {
    loadPage () {
      this.loadIncidentStats()
      this.loadIncidentSLA()
      this.loadIncidentHistogram()
      var end = new Date().getTime()
      var start = new Date().setDate(new Date().getDate() - 7)
      this.timeLineChart = {
        title: 'incidentsHistogram',
        type: 'line',
        events: [],
        start: start,
        end: end,
        interval: {
          unit: 'h',
          value: 12
        }
      }
      this.timeLineChartready = true
    },
    loadIncidentHistogram () {
      this.statusIncidentHistogram('Solved')
      this.statusIncidentHistogram('False Positive')
      this.statusIncidentHistogram('In Process')
    },
    statusIncidentHistogram (status) {
      this.$http
        .get('/api/v1/incidents/histogram', {
          params: {
            status: status,
            start: this.range[0].getTime(),
            end: this.range[1].getTime()
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          return body.data.data
        })
        .then((data) => {
          var buff = {
            data: [],
            key: status,
            value: 0
          }
          data.map((e) => {
            buff.value += e.count
            var keydate = new Date(
              e._id.year + '-' + e._id.month + '-' + e._id.day
            )
            buff.data.push({ doc_count: e.count, key: keydate.getTime() })
          })
          this.timeLineChart.events.push(buff)
        })
    },
    loadIncidentSLA () {
      return this.$http
        .get('/api/v1/incidents/sla', {
          params: {
            start: this.range[0].getTime(),
            end: this.range[1].getTime()
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          return body.data.data
        })
        .then((data) => {
          if (data.length > 0) {
            this.sla.total = data[0].avg_sla
            if (this.sla.total / 1440 > 1) {
              this.sla.total = this.sla.total / 1440
              this.sla.unit = 'Day'
            }
            if (this.sla.total / 60 > 1) {
              this.sla.total = this.sla.total / 60
              this.sla.unit = 'Hour'
            }
            this.sla.total = this.sla.total.toFixed(2)
          }
        })
    },
    loadIncidentStats () {
      return this.$http
        .get('/api/v1/incidents/stats', {
          params: {
            start: this.range[0].getTime(),
            end: this.range[1].getTime()
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          return body.data.data
        })
        .then((data) => {
          data.map((dt) => {
            this.vizData.total.total += dt.count
            if (dt._id === 'Solved') {
              this.vizData.solved.total = dt.count
            }
            if (dt._id === 'In Process') {
              this.vizData.inprocess.total = dt.count
            }
            if (dt._id === 'False Positive') {
              this.vizData.falsePositive.total = dt.count
            }
          })
        })
    }
  }
}
</script>
<style lang="scss">
.incidents-timeline {
  .box {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  width: 75%;
  padding: 0.3rem;
  height: auto;
  display: inline-block;
}

.incidents-sla {
  padding: 0.3rem;
  width: 25%;
  height: auto;
  display: inline-block;
}

.incidents-status {
  margin: 0;
  padding: 0.3rem;
  width: 25%;
  display: inline-block;
}

.status-head {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}
</style>
