<template>
  <box :class="{ 'box--widget': true, [`is-color-${color}`]: color}">
    <div v-if="showTitle" slot="header">
      <div v-if="vizData.title" class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold">
            {{ $t(vizData.title) }}
          </p>
        </div>
      </div>
      <p v-else>
        N/A
      </p>
    </div>
    <visualization
      ref="viz"
      :data="vizData" :loading="loading"
    />
  </box>
</template>
<script>
import { note, search, x } from 'octicons-vue'
import { charts, debounce } from '@/utils'
import Visualization from '@/components/Visualization'

export default {
  components: { Visualization },
  props: {
    vizData: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      timeLock: false,
      customizing: false,
      result: null,
      loading: false,
      rnr: null,
      from: 0
    }
  },
  computed: {
    note () {
      return note
    },
    searchIcon () {
      return search
    },
    x () {
      return x
    },
    widgetColor () {
      if (!this.item || !this.item.color) {
        return ''
      }

      return this.item.color
    },
    vizs () {
      if (!this.result || !(this.result.types instanceof Array)) {
        return []
      }

      return charts().map(viz => {
        if (this.result.types.indexOf(viz.value) < 0) {
          viz.disabled = true
        }

        return viz
      })
    },
    dates () {
      if (!this.item || !this.item.dates) {
        return []
      }

      return this.item.dates.map(x => new Date(x).getTime())
    },
    resultEvents () {
      if (!this.result) {
        return []
      }

      return this.result.events instanceof Array ? this.result.events : []
    },
    resultRange () {
      if (this.item && !this.item.range) {
        return ''
      }

      return this.item.range
    },
    resultTotal () {
      if (!this.result) {
        return []
      }

      return this.result.total instanceof Array ? this.result.events : 0
    },
    resultGeo () {
      return this.result.geo instanceof Array ? this.result.geo : []
    }
  },
  watch: {
    item: {
      deep: true,
      handler () {
        // we should ignore dispatches for
        // item.title & item.viz but hey
        // we are cheating when mutating a computed prop
        // so the newVal and oldVal will always be the same
        // it's kinda impossible to determine dirty changes

        this.$nextTick(() => {
          this.dispatchRnR()
        })
      }
    },
    customizing: 'resize'
  },
  created () {
    this.rnr = debounce(this.registerChangeAndReload, 300)
  },
  mounted () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type !== 'ui/UI_UPDATE_SIDEBAR') {
        return
      }

      setTimeout(() => {
        this.resize()
      }, 300) // ui transition duration
    })
  },
  methods: {
    tablePageUpdated (page) {
      this.from = 10 * (page - 1)
    },
    refresh () {
      if (this.customizing) {
        return
      }

      if (this.item && !this.item.range) {
        // absolute range
        this.search()
        return
      }

      if (!this.$refs.rangepicker) {
        return
      }

      this.$refs.rangepicker.reloadSuggestions()
    },
    resize () {
      if (!this.$refs.viz) {
        return
      }

      this.$refs.viz.resize()
    },
    dispatchRnR () {
      if (!(this.rnr instanceof Function)) {
        return
      }

      this.rnr()
    },
    registerChange () {
      if (!this.customizing) {
        return
      }

      this.$emit('change', this.item)
    },
    registerChangeAndReload () {
      this.registerChange()
      this.search()
    },
    search () {
      if (!this.item || !this.item.query || !this.dates || this.dates.length !== 2) {
        return
      }

      this.loading = true
      return this.$_search({
        query: this.item.query,
        start: this.dates[0],
        end: this.dates[1],
        from: this.from
      })
        .then(normalized => {
          this.result = normalized
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
.box--widget {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .box__header {
    flex-shrink: 0;
    font-weight: normal;
  }

  .box__body {
    flex: 1 1 auto;
    overflow-y: hidden;
  }

  .table-viz {
    height: 100%;
    overflow-y: hidden;
    font-size: 0.9em;

    [data-elm="wrapper"] {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    [data-elm="screen"] {
      flex: 1 1 auto;
      overflow-y: auto;
    }

    [data-elm="plink"] {
      font-size: 1em;
    }
  }
}

.box--moveable {
  border: 2px dashed #ccc;
}
</style>
