<template>
  <div class="playbook-viewer">
    <box v-if="item" class="playbooks-viz">
      <div slot="header" class="filter-row">
        <div class="filter-item ptitle">
          <div class="columns is-vcentered">
            <span class="lable-inline">{{ $t('title') }}: </span>
            <input
              v-if="editing" v-model="item.title" type="text"
              class="input--simple"
            >
            <p v-else class="playbook-title">
              {{ item.title }}
            </p>
          </div>
        </div>
        <div class="filter-item pdescription">
          <div class="columns is-vcentered">
            <span class="lable-inline">{{ $t('description') }}: </span>
            <input
              v-if="editing" v-model="item.description" type="text"
              class="input--simple"
            >
            <p v-else class="playbook-title">
              {{ item.description }}
            </p>
          </div>
        </div>
        <div class="filter-item paction">
          <div class="columns is-vcentered">
            <button v-if="!editing" class="button is-text" @click.prevent="edit()">
              <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
            </button>
            <button v-else class="button is-text" @click.prevent="cancel()">
              <octicon :icon="x" /> <span>{{ $t('cancel') }}</span>
            </button>
            <button :disabled="!editing" class="button is-text" @click.prevent="save()">
              <octicon :icon="check" /> <span>{{ $t('save') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="playbook-container">
        <div id="content">
          <div v-for="(row, k) in asRow" :key="k">
            <div :class="{'action-row': k % 2 === 0, 'action-row-reverse': k % 2 === 1}">
              <div
                v-for="(phase, p) in row" :key="p" class="action-container"
                :class="{'last-item': (k !== (asRow.length - 1) || p !== (row.length - 1)) && p === 4}"
              >
                <!-- End -->
                <div v-if="k === 0 && p === 0" class="action-phase">
                  <div class="action-phase-action">
                    <button v-if="editing" class="button is-text" @click.prevent="addPhase(0, 0)">
                      <octicon :icon="diffAdded" :scale="1.25" />
                    </button>
                  </div>
                  <div class="action-phase-arrow">
                    <octicon :icon="arrowRight" :scale="2" />
                  </div>
                  <div class="action-phase-title">
                    <span>Incident Triggered</span>
                  </div>
                  <div class="action-item-container">
                    <div class="action-item">
                      <div class="action-item-icon" style="color: #78b553">
                        <octicon :icon="play" :scale="5" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End -->
                <div v-else-if="k === (asRow.length - 1) && p === (row.length - 1)" class="action-phase">
                  <div class="action-phase-title">
                    <span>Resolved</span>
                  </div>
                  <div class="action-item">
                    <div class="action-item-icon" style="color: #d59943">
                      <octicon :icon="issueClosed" :scale="5" />
                    </div>
                  </div>
                </div>
                <!-- Items -->
                <drop
                  v-else
                  class="drop action-phase"
                  :class="{ over }"
                  @dragover="over = true"
                  @dragleave="over = false"
                  @drop="handleDrop(k, p, ...arguments)"
                >
                  <div :class="{'action-phase-arrow': k % 2 === 0, 'action-phase-arrow-reverse': k % 2 === 1, 'action-phase-arrow-down': p === 4}">
                    <octicon :icon="arrowRight" :scale="2" />
                  </div>
                  <div class="action-phase-action">
                    <button v-if="editing" class="button is-text" @click.prevent="removePhase(k, p)">
                      <octicon :icon="diffRemoved" :scale="1.25" />
                    </button>
                    <button v-if="editing" class="button is-text" @click.prevent="addPhase(k, p)">
                      <octicon :icon="diffAdded" :scale="1.25" />
                    </button>
                  </div>
                  <div class="action-phase-title">
                    <input
                      v-if="editing" v-model="phase.name" type="text"
                      class="input--simple"
                    >
                    <p v-else class="playbook-title">
                      {{ phase.name }}
                    </p>
                  </div>
                  <div v-if="phase.items.length === 0" class="action-item-placeholder">
                    <div class="action-item-icon">
                      <div v-if="editing" align="center">
                        <octicon :icon="plus" :scale="3" />
                        <p>Drop a action here</p>
                      </div>
                      <div v-else align="center">
                        <octicon :icon="info" :scale="3" />
                        <p>Empty Phase</p>
                      </div>
                    </div>
                  </div>
                  <div class="action-item-container">
                    <div v-for="(itm, i) in phase.items" :key="i" class="action-item">
                      <div class="action-phase-action">
                        <button v-if="editing" class="button is-text" @click.prevent="removeItem(k, p, i)">
                          <octicon :icon="diffRemoved" :scale="1.25" />
                        </button>
                      </div>
                      <div class="action-item-title">
                        <octicon v-if="itm.type === 'email'" :icon="mail" :scale="1.5" />
                        <octicon v-if="itm.type === 'ticket'" :icon="tag" :scale="1.5" />
                        <octicon v-if="itm.type === 'action'" :icon="play" :scale="1.5" />
                        {{ itm.title }}
                      </div>
                      <div v-if="itm.type === 'action'" class="action-item-content">
                        {{ itm.description }}
                      </div>
                      <div v-if="itm.type === 'email'" class="action-item-content">
                        <span>{{ $t('assignedTo') }}</span>
                        <v-select
                          v-model="itm.assign" :options="userList" :multiple="true"
                          :disabled="!editing"
                        />
                        <span>{{ $t('description') }}</span>
                        <input
                          v-model="itm.descrition" class="input" type="text"
                          :disabled="!editing" style="background-color: transparent; height: 32px;font-size: 0.9rem"
                          placeholder="Enter description content"
                        >
                      </div>
                      <div v-if="itm.type === 'ticket'" class="action-item-content">
                        {{ $t('maintainer') }}
                        <v-select
                          v-model="itm.maintainer" :options="userList" :multiple="true"
                          :disabled="!editing"
                        />
                        {{ $t('assignedTo') }}
                        <v-select
                          v-model="itm.assign" :options="userList" :multiple="true"
                          :disabled="!editing"
                        />
                      </div>
                    </div>
                  </div>
                </drop>
              </div>
            </div>
          </div>
        </div>
      </div>
    </box>
    <box class="actions-viz">
      <div slot="header" class="filter-row">
        <div class="filter-item" style="width: 100%">
          <div class="columns is-vcentered">
            <span class="lable-inline">{{ $t('actionsList') }}</span>
          </div>
        </div>
      </div>
      <div class="playbook-container">
        <div width="100%" align="center">
          <p style="color: #8e8e8e">
            ----- Ticket -----
          </p>
        </div>
        <div class="action-item">
          <drag :class="{'drag': true, 'disabled': !editing}" :draggable="editing" :transfer-data="{ type: 'ticket', title: 'Open Ticket', content: 'Open a ticket when an incident triggered', maintainer: [user._id], assign: [], priority: 'High' }">
            <div class="action-item-title">
              <octicon :icon="tag" :scale="1.5" />
              Open Ticket
            </div>
            <div class="action-item-content">
              Open a ticket when an incident triggered
            </div>
          </drag>
        </div>
        <div width="100%" align="center">
          <p style="color: #8e8e8e">
            ----- Email -----
          </p>
        </div>
        <div class="action-item">
          <drag :class="{'drag': true, 'disabled': !editing}" :draggable="editing" :transfer-data="{ type: 'email', title: 'Send Email', content: 'Send email when an incident triggered', receiver: [] }">
            <div class="action-item-title">
              <octicon :icon="mail" :scale="1.5" />
              Send Email
            </div>
            <div class="action-item-content">
              Send email when an incident triggered
            </div>
          </drag>
        </div>
        <div width="100%" align="center">
          <p style="color: #8e8e8e">
            ----- Actions -----
          </p>
        </div>
        <div v-for="(itm, i) in actions.items" :key="i" class="action-item">
          <drag :class="{'drag': true, 'disabled': !editing}" :draggable="editing" :transfer-data="{ type: 'action', id: itm.id, title: itm.title, description: itm.description }">
            <div class="action-item-title">
              <octicon :icon="play" :scale="1.5" />
              {{ itm.title }}
            </div>
            <div class="action-item-content">
              {{ itm.description }}
            </div>
          </drag>
        </div>
      </div>
    </box>
  </div>
</template>
<script>
import { pencil, check, play, issueClosed, arrowRight, diffAdded, diffRemoved, plus, info, tag, mail, x } from 'octicons-vue'
import { Drag, Drop } from 'vue-drag-drop'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'PlaybookViewer',
  components: { Drop, Drag, vSelect },
  data () {
    return {
      item: {},
      userNames: [],
      actions: [],
      editing: true,
      phases: [],
      over: false
    }
  },
  computed: {
    playbookID () {
      return this.$route.query.id
    },
    isAdd () {
      return this.$route.query.action === 'add'
    },
    isView () {
      return this.$route.query.action === 'view'
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    mail () {
      return mail
    },
    tag () {
      return tag
    },
    info () {
      return info
    },
    pencil () {
      return pencil
    },
    play () {
      return play
    },
    check () {
      return check
    },
    arrowRight () {
      return arrowRight
    },
    diffAdded () {
      return diffAdded
    },
    diffRemoved () {
      return diffRemoved
    },
    issueClosed () {
      return issueClosed
    },
    userList () {
      return this.userNames.map(c => c.email)
    },
    user () {
      return this.$store.getters['authentication/user']
    },
    asRow () {
      if (!this.item || !this.item.phases) {
        return
      }
      var row = [{ name: 'Start', items: [] }]
      var buff = []
      for (var i = 0; i < this.item.phases.length; i++) {
        row.push(this.item.phases[i])
        if (row.length % 5 === 0) {
          buff.push(row)
          row = []
        }
      }
      if (row.length === 5) {
        buff.push(row)
      }
      row.push({ name: 'End', items: [] })
      buff.push(row)
      return buff
    }
  },
  watch: {
    actions () {
      this.loadPhases()
    },
    item () {
      this.loadPhases()
    }
  },
  mounted () {
    if (this.isView) {
      this.editing = false
    }
    this.getActions()
    if (!this.isAdd) {
      this.findPlaybook()
    } else {
      this.item = {
        title: '',
        description: '',
        phases: []
      }
      this.editing = true
    }
    this.loadUserName()
  },
  methods: {
    loadPhases () {
      if (!this.item || !this.item.phases) {
        return
      }
      for (var i = 0; i < this.item.phases.length; i++) {
        var itmBuff = []
        this.item.phases[i].items.map(d => {
          if (d.type !== 'action') {
            itmBuff.push(d)
          } else {
            itmBuff.push(this.findAction(d.id))
          }
        })
        this.item.phases[i].items = itmBuff
      }
    },
    findAction (id) {
      if (!this.actions || !id || !this.actions.items || this.actions.items.length === 0) {
        return { title: 'Unknow Action', type: 'action', description: '' }
      }
      var element
      this.actions.items.map(c => {
        if (c.id === id) {
          element = c
        }
      })
      if (!element) {
        return { id: id, title: 'Unknow Action', type: 'action', description: '' }
      }
      return { id: id, title: element.title, type: 'action', description: element.description }
    },
    edit () {
      this.editing = !this.editing
    },
    save () {
      if (this.isAdd) {
        this.store()
      } else {
        this.update()
      }
    },
    cancel () {
      if (!window.confirm(this.$t('rus'))) {
        return
      }
      if (this.isAdd) {
        window.location.href = '/#/playbooks'
      } else {
        this.editing = false
        this.findPlaybook()
      }
    },
    removeItem (k, p, index) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      var pindex = k * 5 + p - 1
      var buff = []

      var items = this.item.phases[pindex].items
      for (var i = 0; i < items.length; i++) {
        if (i !== index) {
          buff.push(items[i])
        }
      }
      this.item.phases[pindex].items = buff
    },
    addPhase (k, p) {
      var index = k * 5 + p
      if (index === this.item.phases.length) {
        this.item.phases.push({
          name: `Phase ${index + 1}`,
          items: []
        })
        return
      }

      var buff = []
      for (var i = 0; i < this.item.phases.length; i++) {
        if (i === index) {
          buff.push({
            name: `Phase ${i + 1}`,
            items: []
          })
        }
        buff.push(this.item.phases[i])
      }
      this.item.phases = buff
    },
    removePhase (k, p) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }
      var index = k * 5 + p - 1

      var buff = []
      for (var i = 0; i < this.item.phases.length; i++) {
        if (i !== index) {
          buff.push(this.item.phases[i])
        }
      }
      this.item.phases = buff
    },
    handleDrop (k, p, data) {
      var index = k * 5 + p - 1
      this.over = false
      this.item.phases[index].items.push(data)
    },
    store () {
      return this.$http.post('/api/v1/playbooks', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })
      })
    },
    update () {
      return this.$http.patch(`/api/v1/playbooks/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })
        this.editing = false
        this.findPlaybook()
      })
    },
    findPlaybook () {
      return this.$http.get('/api/v1/playbooks/find', {
        params: {
          id: this.playbookID
        }
      }).then(body => {
        if (!body || !body.data) {
          return []
        }
        if (!body.data.data && body.data.data.length === 0) {
          this.$store.dispatch('NOTIFY', {
            type: 'error',
            text: 'Cannot find ticket ID. Redirect to Home'
          })
          setTimeout(function () {
            window.location.href = '/#/playbooks'
          }, 2000)
        }
        this.item = body.data.data[0]
      })
    },
    loadUserName () {
      return this.$http.get('/api/v1/users').then(body => {
        if (!body || !body.data) {
          return []
        }

        return body.data.data
      }).then(users => {
        this.userNames = users.items
      })
    },
    getActions () {
      return this.$http.get('/api/v1/api-channels', {
        params: {
          filter: '',
          sort: '_id',
          order: 'asc',
          page: 1,
          limit: 1000
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        this.actions = body.data.data
      })
    }
  }
}
</script>
<style scoped>
.input--simple {
  padding: 0 !important;
}
</style>
<style lang="scss">
  .action-item-content {
    .vs--disabled .vs__open-indicator, .vs--disabled .vs__search {
      background-color: unset !important;
    }
    .v-select .vs__dropdown-toggle {
      background-color: unset;
    }
  }
  .vs__dropdown-menu {
    position: relative;
    z-index: 9;
  }
  #content {
    min-height: 650px;
  }
  .action-row {
    flex-direction: row;
    display: flex;

  }
  .action-row-reverse {
    flex-direction: row-reverse;
    display: flex;
  }
  .action-container {
    width: 20%;
    min-height: 166px;
    padding: 0.5rem;
    display: inline-block;
    height: fit-content;
  }
  .action-container.last-item {
    height: auto;
  }
  .action-phase {
    position: relative;
    padding: 0.5rem;
    height: 100%;
    background-color: #4f5c818c;
    border-radius: 5px;
  }
  .action-phase-title {
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .action-phase-action {
    position: absolute;
    top: 0.2rem;
    right: 0.5rem;
    z-index: 9999;
  }
  .action-phase-arrow {
    width: 1rem;
    position: absolute;
    z-index: 9999;
    right: -1rem;
    top: 80px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .action-phase-arrow-reverse {
    width: 1rem;
    position: absolute;
    z-index: 9999;
    top: 80px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) rotate(180deg);
    left: -1rem;
  }
  .action-phase-arrow-down {
    bottom: -1.5rem;
    right: 50%;
    left: unset;
    top: unset;
    transform: rotate(90deg);
  }
  .action-phase-action button{
    padding: 0;
  }
  .action-item-placeholder {
    position: relative;
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    border: 1px solid #838383;
  }
  .action-item-container {
    margin: -0.5rem;
    margin-bottom: 0;
    padding: 0.5rem;
    padding-bottom: 0;
    max-height: 217px;
    overflow: unset;
    overflow-y: auto;
  }
  .action-item {
    position: relative;
    width: 100%;
    min-height: 155px;
    height: fit-content;
    margin-bottom: 0.5rem;
    background: #585e6f;
    border-radius: 5px;
    border: 1px solid #585e6f;
  }
  .action-item-title {
    padding: 0.3rem;
    border-bottom: 1px solid #8a8a8a;
    background-color: #344d8e;
    height: 35px;
    font-weight: bolder;
  }
  .action-item-content {
    font-size: 0.9rem;
    padding: 0.3rem;
  }
  .action-item-icon {
    width: 100%;
    height: 100%;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .drag {
    cursor: move;
  }
  .drag.disabled {
    cursor: default;
  }
  .drop.over {
    background: #4f5c81;
  }
  .ptitle {
    width: 20% !important;
  }
  .pdescription {
    width: 40% !important;
  }
  .pdescription span {
    margin-left: 1rem;
  }
  .paction {
    float: right;
    margin-top: 0.5rem;
  }
  .playbook-title {
    margin-left: 0.5rem;
  }
  .playbook-container {
    margin: -0.75rem;
    padding: 0.5rem;
    max-height: 680px;
    overflow: hidden;
    overflow-y: auto;
  }
</style>
