<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.incidents') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.incidents') }}
        </div>
        <div class="level-right">
          <range-picker
            ref="rangepicker" :range.sync="dates" :lookup.sync="range"
          />
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t('newIncident') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <div class="incidents-dashboard">
      <incident-stats :range="dates" />
      <div class="incidents-table">
        <box>
          <div slot="header" class="filter-row">
            <div class="filter-item" style="width: 49%">
              <div class="columns is-vcentered">
                <span class="lable-inline">{{ $t('incidentsDetail') }}</span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Status: </span>
                <span class="select">
                  <select v-model="statusFilter" :class="{'select-setting': true, 'is-color-red': statusFilter === 'False Positive', 'is-color-yellow': statusFilter === 'In Process', 'is-color-green': statusFilter === 'Solved' }" @change="changeFilter">
                    <option class="is-color-teal" selected="true" value="">All</option>
                    <option class="is-color-red" :selected="statusFilter === 'False Positive'" value="False Positive">
                      {{ $t('falsePositive') }}
                    </option>
                    <option class="is-color-yellow" :selected="statusFilter === 'In Process'" value="In Process">
                      {{ $t('inProcess') }}
                    </option>
                    <option class="is-color-green" :selected="statusFilter === 'Solved'" value="Solved">
                      {{ $t('solved') }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Priority: </span>
                <span class="select">
                  <select v-model="priorityFilter" :class="{'select-setting': true, 'is-color-red': priorityFilter === 'Critical', 'is-color-yellow': priorityFilter === 'High', 'is-color-blue': priorityFilter === 'Medium', 'is-color-green': priorityFilter === 'Low' }" @change="changeFilter">
                    <option class="is-color-teal" selected="true" value="">All</option>
                    <option class="is-color-red" :selected="priorityFilter === 'Critical'" value="Critical">
                      {{ $t('critical') }}
                    </option>
                    <option class="is-color-yellow" :selected="priorityFilter === 'High'" value="High">
                      {{ $t('high') }}
                    </option>
                    <option class="is-color-blue" :selected="priorityFilter === 'Medium'" value="Medium">
                      {{ $t('medium') }}
                    </option>
                    <option class="is-color-green" :selected="priorityFilter === 'Low'" value="Low">
                      {{ $t('low') }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Filter: </span>
                <input
                  v-model="textFilter" class="input" type="text"
                  placeholder="Filter value"
                >
              </div>
            </div>
          </div>
          <data-table
            ref="table" :items="items" sort-by="_id"
            :theme="theme" :filter="textFilter"
            :disable-filtering="false"
          >
            <data-column field="title" :label="$t('title')" width="15%" />
            <data-column field="description" :label="$t('description')" />
            <data-column :label="$t('infomation')" width="20%" :sortable="false">
              <template slot-scope="props">
                <div class="is-text is-size-7 info-line">
                  <div class="info-lable">
                    {{ $t('createdAt') }}:
                  </div>
                  <div class="info-value">
                    <span class="tag is-default is-size-7 is-light">{{ props.item.created_at ? format(new Date(props.item.created_at), 'dd/MM/yyyy HH:mm:ss') : 'None' }}</span>
                  </div>
                </div>
                <div class="is-text is-size-7 info-line">
                  <div class="info-lable">
                    {{ $t('priority') }}:
                  </div>
                  <div class="info-value">
                    <select :class="{'select-setting': true, 'is-color-red': props.item.priority === 'Critical', 'is-color-yellow': props.item.priority === 'High', 'is-color-blue': props.item.priority === 'Medium', 'is-color-green': props.item.priority === 'Low' }" @change="changePriority($event,props.item)">
                      <option class="is-color-red" :selected="props.item.priority === 'Critical'" value="Critical">
                        {{ $t('critical') }}
                      </option>
                      <option class="is-color-yellow" :selected="props.item.priority === 'High'" value="High">
                        {{ $t('high') }}
                      </option>
                      <option class="is-color-blue" :selected="props.item.priority === 'Medium'" value="Medium">
                        {{ $t('medium') }}
                      </option>
                      <option class="is-color-green" :selected="props.item.priority === 'Low'" value="Low">
                        {{ $t('low') }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="is-text is-size-7 info-line">
                  <div class="info-lable">
                    {{ $t('status') }}:
                  </div>
                  <div class="info-value">
                    <select :class="{'select-setting': true, 'is-color-red': props.item.status === 'False Positive', 'is-color-yellow': props.item.status === 'In Process', 'is-color-green': props.item.status === 'Solved' }" @change="changeStatus($event,props.item)">
                      <option class="is-color-red" :selected="props.item.status === 'False Positive'" value="False Positive">
                        {{ $t('falsePositive') }}
                      </option>
                      <option class="is-color-yellow" :selected="props.item.status === 'In Process'" value="In Process">
                        {{ $t('inProcess') }}
                      </option>
                      <option class="is-color-green" :selected="props.item.status === 'Solved'" value="Solved">
                        {{ $t('solved') }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="is-text is-size-7 info-line">
                  <div class="info-lable">
                    {{ $t('maintainer') }}:
                  </div>
                  <div class="info-value">
                    <span v-for="e in props.item.maintainer" :key="e" class="tag is-text is-size-7 is-light">{{ e }}</span>
                  </div>
                </div>
              </template>
            </data-column>
            <data-column :label="$t('settings')" width="15%" :sortable="false">
              <template slot-scope="props">
                <p>
                  <span v-if="props.item.setting.first_alert" class="tag is-success is-light">{{ $t('alertToMaintainer') }}</span>
                  <span v-else class="tag is-warning is-light">{{ $t('dontAlertToMaintainer') }}</span>
                </p>
                <p v-if="props.item.setting.remind">
                  <span class="tag is-default is-light">{{ $t('deadline') }}: {{ props.item.setting.deadline ? format(new Date(props.item.setting.deadline), 'dd/MM/yyyy HH:mm:ss') : 'None' }}</span>
                  <span class="tag is-default is-light">{{ $t('remindBeforeDeadline') }}: {{ props.item.setting.offset }} Minutes</span>
                </p>
                <p v-else>
                  <span class="tag is-warning is-light">{{ $t('dontRemind') }}</span>
                </p>
              </template>
            </data-column>
            <data-column :label="$t('toDo')" width="15%" :sortable="false">
              <template slot-scope="props">
                <p><span v-for="e in props.item.ticket_id" :key="e" class="tag is-warning is-light todo"><octicon :icon="tag" /> <b>{{ e }}</b></span></p>
                <p><span v-for="(e, i) in props.item.actions" :key="i" class="tag is-success is-light todo"><octicon :icon="play" /> <b>{{ e.name }}</b></span></p>
                <p><span v-for="(e, i) in props.item.playbooks" :key="i" class="tag is-primary is-light todo"><octicon :icon="repoClone" /> <b>{{ e.name }}</b></span></p>
                <p><span v-for="e in props.item.recipients" :key="e" class="tag is-text is-light todo"><octicon :icon="mail" /> <b>{{ e }}</b></span></p>
              </template>
            </data-column>
            <data-column
              :label="$t('actions')" class="has-text-centered" :sortable="false"
              width="10%"
            >
              <template slot-scope="props">
                <div class="has-text-left">
                  <button class="button is-text" @click.prevent="showEvents(props.item)">
                    <octicon :icon="linkExternal" /> <span>{{ $t('events') }}</span>
                  </button>
                  <button class="button is-text" @click.prevent="edit(props.item)">
                    <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
                  </button>
                  <button class="button is-text" @click.prevent="destroy(props.item)">
                    <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
                  </button>
                </div>
              </template>
            </data-column>
          </data-table>
        </box>
      </div>
    </div>
    <modal v-if="item" :show.sync="modal">
      <box style="min-height: 450px">
        <div slot="header">
          {{ item.title || $t('newIncident') }}
        </div>
        <form @submit.prevent="submit">
          <div class="stepper">
            <div :class="{'stepper-item': true, 'stepper-done': stepper > 1, 'stepper-active': stepper === 1}" @click="stepper=1">
              <span class="steper-header">{{ $t('infomation') }}</span>
            </div>
            <div :class="{'stepper-item': true, 'stepper-done': stepper > 2, 'stepper-active': stepper === 2}" @click="stepper=2">
              <span class="steper-header">{{ $t('settings') }}</span>
            </div>
            <div :class="{'stepper-item': true, 'stepper-done': stepper > 3, 'stepper-active': stepper === 3}" @click="stepper=3">
              <span class="steper-header">{{ $t('toDo') }}</span>
            </div>
          </div>
          <transition name="expand">
            <div>
              <div v-show="stepper === 1">
                <div class="field">
                  <label class="label">{{ $t('title') }}</label>
                  <div class="control">
                    <input v-model="item.title" class="input" type="text">
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('description') }}</label>
                  <div class="control">
                    <input v-model="item.description" class="input" type="text">
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('events') }}</label>
                  <div class="control">
                    <textarea
                      v-model="item.events" class="textarea" rows="2"
                      required
                    />
                  </div>
                </div>
              </div>
              <div v-show="stepper === 2">
                <div class="group-field">
                  <div class="field-2">
                    <label class="label">{{ $t('priority') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.priority" :options="priorityList" style="color: white"
                        />
                      </p>
                    </div>
                  </div>
                  <div class="field-2">
                    <label class="label">{{ $t('maintainer') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.maintainer" :options="userList" :multiple="true"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <label class="label">{{ $t('settings') }}</label>
                <div class="group-field-setting">
                  <div class="field-line">
                    <div class="field-2">
                      <input
                        id="first-alert" v-model="item.setting.first_alert" type="checkbox"
                        name="first-alert" class="switch"
                      >
                      <label for="first-alert">{{ item.setting.first_alert ? $t('alertToMaintainer') : $t('dontAlertToMaintainer') }}</label>
                    </div>
                    <div class="field-2">
                      <input
                        id="remind" v-model="item.setting.remind" type="checkbox"
                        name="remind" class="switch"
                      >
                      <label for="remind">{{ item.setting.remind ? $t('remind') : $t('dontRemind') }}</label>
                    </div>
                  </div>
                  <div v-show="item.setting.remind" class="field-line">
                    <div class="field-2">
                      <label class="label">{{ $t('deadline') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <date-picker
                            :value.sync="selectedDeadline" time required
                          />
                        </p>
                      </div>
                    </div>
                    <div class="field-2">
                      <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <span class="select" style="width: 100%">
                            <select v-model="item.setting.offset" style="width: 100%">
                              <option selected="true" :value="15">15 Mins</option>
                              <option :value="30">30 Mins</option>
                              <option :value="60">1 Hour</option>
                              <option :value="180">3 Hours</option>
                              <option :value="360">6 Hours</option>
                              <option :value="720">12 Hours</option>
                              <option :value="1440">1 Day</option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="stepper === 3">
                <tabs :theme="$_ui_theme_tabs">
                  <tab :title="$t('email')" style="padding: 1em 0">
                    <div class="field">
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <input
                            v-model="email" class="input" type="text"
                            :placeholder="$t('email')"
                          >
                        </p>
                        <p class="control">
                          <a class="button is-info" @click.prevent="recipientAdd">{{ $t('add') }}</a>
                        </p>
                      </div>
                      <div v-for="(e, index) in item.recipients" :key="e" class="field is-grouped">
                        <p class="control is-expanded">
                          <input
                            class="input is-small" type="text" :placeholder="$t('email')"
                            :value="e"
                          >
                        </p>
                        <p class="control">
                          <a class="button is-small is-danger" @click.prevent="recipientRemove(index)"><octicon :icon="x" /></a>
                        </p>
                      </div>
                    </div>
                  </tab>
                  <tab :title="$t('tickets')" style="padding: 1em 0">
                    <div class="field">
                      <input
                        id="ticket-alert" v-model="item.ticket_setting.enabled" type="checkbox"
                        name="ticket-alert" class="switch"
                      >
                      <label for="ticket-alert">{{ item.ticket_setting.enabled ? $t('enabled') : $t('disabled') }}</label>
                    </div>
                    <div v-show="item.ticket_setting.enabled" class="field">
                      <div class="group-field">
                        <div class="field-auto" style="width: -webkit-fill-available;">
                          <label class="label">{{ $t('assignedTo') }}</label>
                          <div class="field is-grouped">
                            <p class="control is-expanded">
                              <v-select
                                v-model="item.ticket_setting.assign" :options="userList" :multiple="true"
                              />
                            </p>
                          </div>
                        </div>
                        <div class="field-auto" style="margin-top: 32px;width: fit-content;height: 34px">
                          <button
                            id="deadline-btn" class="button" type="button"
                            :class="item.ticket_setting.remind ? 'is-success' : 'is-default'"
                            title="Set Deadline" @click="item.ticket_setting.remind = !item.ticket_setting.remind"
                          >
                            <octicon :icon="clock" :scale="1.5" /><br><span />
                          </button>
                        </div>
                      </div>
                      <div v-show="item.ticket_setting.remind" class="field-line">
                        <div class="field-2">
                          <label class="label">{{ $t('deadline') }}</label>
                          <div class="field is-grouped">
                            <p class="control is-expanded">
                              <span class="select" style="width: 100%">
                                <select v-model="item.ticket_setting.deadline" style="width: 100%">
                                  <option :value="60">1 Hour</option>
                                  <option :value="180">3 Hours</option>
                                  <option :value="360">6 Hours</option>
                                  <option :value="720">12 Hours</option>
                                  <option :value="1440">1 Day</option>
                                  <option :value="2880">2 Day</option>
                                  <option :value="4320">3 Day</option>
                                </select>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="field-2">
                          <label class="label">{{ $t('remindBeforeDeadline') }}</label>
                          <div class="field is-grouped">
                            <p class="control is-expanded">
                              <span class="select" style="width: 100%">
                                <select v-model="item.ticket_setting.offset" style="width: 100%">
                                  <option selected="true" :value="15">15 Mins</option>
                                  <option :value="30">30 Mins</option>
                                  <option :value="60">1 Hour</option>
                                  <option :value="180">3 Hours</option>
                                  <option :value="360">6 Hours</option>
                                  <option :value="720">12 Hours</option>
                                  <option :value="1440">1 Day</option>
                                </select>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab>
                  <tab :title="$t('actions')" style="padding: 1em 0">
                    <div class="field">
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <v-select
                            v-model="selectedActions" :options="actionsName" :multiple="true"
                          />
                        </p>
                      </div>
                    </div>
                  </tab>
                  <tab :title="$t('playbook')" style="padding: 1em 0">
                    <div class="field">
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <v-select
                            v-model="selectedPlaybooks" :options="playbooksName" :multiple="true"
                          />
                        </p>
                      </div>
                    </div>
                  </tab>
                </tabs>
              </div>
            </div>
          </transition>
        </form>
        <div slot="footer" class="field is-grouped is-grouped-right">
          <div v-show="stepper > 1" class="control">
            <button type="button" class="button is-primary" @click.prevent="stepper--">
              {{ $t('previous') }}
            </button>
          </div>
          <div v-show="stepper < 3" class="control">
            <button type="button" class="button is-primary" @click.prevent="stepper++">
              {{ $t('next') }}
            </button>
          </div>
          <div v-show="stepper === 3" class="control">
            <button type="submit" class="button is-link" @click="submit">
              {{ $t('save') }}
            </button>
          </div>
          <div class="control">
            <button type="button" class="button is-link is-light" @click.prevent="closeModal">
              {{ $t('cancel') }}
            </button>
          </div>
        </div>
      </box>
    </modal>
    <modal :show.sync="modalEvent">
      <box>
        <div slot="header">
          {{ $t('eventsViewer') }}
        </div>
        <snippet v-if="isJSON(modalEventData)" :code="modalEventData" language="json" />
        <p v-else>
          {{ modalEventData }}
        </p>
        <div slot="footer" class="field is-grouped is-grouped-right">
          <div class="control">
            <button type="button" class="button is-link is-light" @click.prevent="closeModal">
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal, Tabs, Tab, DatePicker, Snippet } from '@cyradar/ui'
import RangePicker from '@/components/RangePicker'
import { format } from 'date-fns'
import { plus, x, pencil, chevronDown, chevronUp, linkExternal, tag, play, repoClone, mail, info, clock } from 'octicons-vue'
import vSelect from 'vue-select'
import { isJSON } from '@/utils'
import IncidentStats from '@/components/IncidentStats'
import 'vue-select/dist/vue-select.css'

export default {
  components: { IncidentStats, DataTable, DataColumn, Modal, Tabs, Tab, vSelect, DatePicker, Snippet, RangePicker },
  data () {
    return {
      item: {
        setting: {
          first_alert: true,
          remind: false,
          deadline: new Date()
        },
        ticket_setting: {
          maintainer: [],
          enabled: false,
          assign: [],
          remind: false,
          deadline: 1440,
          offset: 30
        }
      },
      dates: [],
      ticketconfig: {
        remind: false,
        deadline: new Date(),
        offset: 30,
        assign: []
      },
      range: 'day-30',
      stepper: 1,
      priorityFilter: '',
      statusFilter: '',
      textFilter: '',
      selectedDeadline: new Date(),
      modal: false,
      modalEvent: false,
      expanded: false,
      modalEventData: '',
      priorityList: ['Critical', 'High', 'Medium', 'Low'],
      selectedActions: [],
      selectedPlaybooks: [],
      playbookList: [],
      apiChannels: [],
      userNames: [],
      email: ''
    }
  },
  computed: {
    theme () {
      return {
        ...this.$_ui_theme_tables,
        datatable__filter: 'disable-filter'
      }
    },
    clock () {
      return clock
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    chevronDown () {
      return chevronDown
    },
    chevronUp () {
      return chevronUp
    },
    linkExternal () {
      return linkExternal
    },
    tag () {
      return tag
    },
    play () {
      return play
    },
    info () {
      return info
    },
    repoClone () {
      return repoClone
    },
    mail () {
      return mail
    },
    actionsName () {
      return this.apiChannels.map(c => c.title)
    },
    userList () {
      return this.userNames.map(c => c.email)
    },
    playbooksName () {
      return ['test_1', 'test_2']
    },
    user () {
      return this.$store.getters['authentication/user']
    }
  },
  watch: {
    dates () {
      if (this.dates.length < 2) {
        return
      }
      this.$refs.table.loadItems()
    }
  },
  mounted () {
    this.getPlaybooks()
    this.loadAPIChannels()
    this.loadUserName()
  },
  methods: {
    isJSON,
    format,
    changeFilter () {
      this.$refs.table.loadItems()
    },
    changeStatus (e, itm) {
      itm.status = e.target.value
      this.item = itm
      return this.$http.patch(`/api/v1/incidents/resolved/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    changePriority (e, itm) {
      itm.priority = e.target.value
      this.item = itm
      this.update()
    },
    closeModal () {
      this.modal = false
      this.modalEvent = false
    },
    showEvents (item) {
      this.modalEvent = true
      this.modalEventData = item.events
    },
    submit () {
      this.item.actions = []
      this.selectedActions.map(c => {
        this.item.actions.push({ name: c })
      })
      this.item.playbooks = []
      this.selectedPlaybooks.map(c => {
        this.item.playbooks.push({ name: c })
      })
      this.item.setting.offset = parseInt(this.item.setting.offset)
      this.item.setting.deadline = this.selectedDeadline
      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    edit (item) {
      this.selectedPlaybooks = item.playbooks.map(c => c.name)
      this.selectedActions = item.actions.map(c => c.name)
      this.selectedDeadline = new Date(item.setting.deadline)
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      return this.$http.post('/api/v1/incidents', this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    update () {
      return this.$http.patch(`/api/v1/incidents/${this.item.id}`, this.item).then(body => {
        if (!body || !body.data) {
          return
        }

        this.closeModal()
        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/incidents/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/incidents', {
        params: {
          priority: this.priorityFilter,
          status: this.statusFilter,
          filter: filter.query,
          sort: order.by,
          start: this.dates[0].getTime(),
          end: this.dates[1].getTime(),
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    recipientAdd () {
      if (!this.item.recipients) {
        this.item.recipients = []
      }

      this.item.recipients.push(this.email)
      this.email = ''
    },
    recipientRemove (idx) {
      if (!this.item.recipients) {
        this.item.recipients = []
      }

      this.item.recipients.splice(idx, 1)
    },
    create () {
      this.item = {
        setting: {
          first_alert: false,
          remind: false,
          deadline: new Date(),
          offset: 30
        },
        ticket_setting: {
          maintainer: [],
          enabled: false,
          assign: [],
          remind: false,
          deadline: 1440,
          offset: 30
        },
        maintainer: [this.user.id()],
        ticket_id: [],
        actions: [],
        playbooks: [],
        recipients: []
      }
      this.modal = true
    },
    loadUserName () {
      return this.$http.get('/api/v1/users').then(body => {
        if (!body || !body.data) {
          return []
        }

        return body.data.data
      }).then(users => {
        this.userNames = users.items
      })
    },
    getPlaybooks () {
      return this.$http.get('/api/v1/playbooks', {
        params: {
          filter: '',
          sort: '_id',
          order: 'asc',
          page: 1,
          limit: 1000
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        this.playbookList = body.data.data.items
      })
    },
    loadAPIChannels () {
      return this.$http.get('/api/v1/api-channels/names').then(body => {
        if (!body || !body.data) {
          return []
        }

        return body.data.data
      }).then(channels => {
        this.apiChannels = channels
      })
    }
  }
}
</script>
<style lang="scss">

.stepper {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}
.stepper-item {
  display: inline-block;
  width: 33%;
  cursor: pointer;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #8a8a8a;
  text-align: center;
  .steper-header {
    color: #8a8a8a;
    font-weight: bolder;
  }
}

.stepper-done {
  border-bottom-color: #3298dc;
  .steper-header {
    color: #3298dc;
  }
}

.stepper-active {
  border-bottom-color: #3273dc;
  .steper-header {
    color: #3273dc;
  }
}

.disable-filter {
  display: none;
}

.lable-inline {
  padding: 0.2rem;
}

.filter-item {
  width: 17%;
  display: inline-block;
}

.filter-row {
  width: 100%;
  padding: 0.5rem;
  display: flex;
}

.select-setting {
  border-radius: 5px;
}

.expand-transition {
  transition: width 5s ease;
  height: 30px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

.todo {
  margin: 0.1rem;
  min-width: 80px;
  padding-left: 0;
  .octicon {
    margin-right: 0.1rem;
  }
}

.info-line {
  margin-bottom: 0.25rem;
  display: flex;
}

.info-lable {
  min-width: 80px;
  padding-top: 0.1rem;
  display: inline-block;
}

.info-value span {
  margin-bottom: 0.1rem;
}

.vs--single .vs__selected {
  background-color: #f0f0f0;
}

.group-field {
  margin-bottom: .75rem;
  width: 100%;
  display: flex;
}

.field-line {
  width: 100%;
  padding: 0.2rem 0.2rem .75rem 0.2rem;
  display: flex;
}

.field-2 {
  width: 50%;
  padding-right: 0.2rem;
  display: inline-block;
}

.incidents-table {
  padding: 0.3rem;
}

.incidents-seconds {
  width: 100%;
  display: flex;
}

.range-picker-advanced {
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
}
</style>
