<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.tickets') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.tickets') }}
        </div>
        <div class="level-right">
          <range-picker
            v-show="!ticketID || ticketID.length === 0"
            ref="rangepicker"
            :range.sync="dates"
            :lookup.sync="range"
          />
          <button class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t('newTicket') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <div
      v-if="typeof ticketID === 'undefined' || ticketID.length === 0"
      class="incidents-dashboard"
    >
      <div class="status-head">
        <div class="incidents-status">
          <standard-chart
            v-if="ready"
            ref="chart"
            :viz-data="vizData.closed"
            color="blue"
          />
        </div>
        <div class="incidents-status">
          <standard-chart
            v-if="ready"
            ref="chart"
            :viz-data="vizData.pending"
            color="yellow"
          />
        </div>
        <div class="incidents-status">
          <standard-chart
            v-if="ready"
            ref="chart"
            :viz-data="vizData.overdue"
            color="red"
          />
        </div>
        <div class="incidents-status">
          <standard-chart
            v-if="ready"
            ref="chart"
            :viz-data="vizData.unassigned"
          />
        </div>
      </div>
      <div class="incidents-table">
        <box>
          <div slot="header" class="filter-row">
            <div class="filter-item" style="width: 49%">
              <div class="columns is-vcentered">
                <span class="lable-inline">{{ $t('incidentsDetail') }}</span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Status: </span>
                <span class="select">
                  <select
                    v-model="statusFilter"
                    :class="{
                      'select-setting': true,
                      'is-color-red': statusFilter === 'Overdue',
                      'is-color-yellow': statusFilter === 'Pending',
                      'is-color-green': statusFilter === 'Closed',
                      'is-color-teal': statusFilter === 'Unassigned'
                    }"
                    @change="changeFilter"
                  >
                    <option class="is-color-teal" selected="true" value="">
                      All
                    </option>
                    <option
                      class="is-color-red"
                      :selected="statusFilter === 'Overdue'"
                      value="Overdue"
                    >
                      {{ $t('overdue') }}
                    </option>
                    <option
                      class="is-color-yellow"
                      :selected="statusFilter === 'Pending'"
                      value="Pending"
                    >
                      {{ $t('pending') }}
                    </option>
                    <option
                      class="is-color-teal"
                      :selected="statusFilter === 'Unassigned'"
                      value="Unassigned"
                    >
                      {{ $t('unassigned') }}
                    </option>
                    <option
                      class="is-color-green"
                      :selected="statusFilter === 'Closed'"
                      value="Closed"
                    >
                      {{ $t('closed') }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Priority: </span>
                <span class="select">
                  <select
                    v-model="priorityFilter"
                    :class="{
                      'select-setting': true,
                      'is-color-red': priorityFilter === 'Critical',
                      'is-color-yellow': priorityFilter === 'High',
                      'is-color-blue': priorityFilter === 'Medium',
                      'is-color-green': priorityFilter === 'Low'
                    }"
                    @change="changeFilter"
                  >
                    <option class="is-color-teal" selected="true" value="">
                      All
                    </option>
                    <option
                      class="is-color-red"
                      :selected="priorityFilter === 'Critical'"
                      value="Critical"
                    >
                      {{ $t('critical') }}
                    </option>
                    <option
                      class="is-color-yellow"
                      :selected="priorityFilter === 'High'"
                      value="High"
                    >
                      {{ $t('high') }}
                    </option>
                    <option
                      class="is-color-blue"
                      :selected="priorityFilter === 'Medium'"
                      value="Medium"
                    >
                      {{ $t('medium') }}
                    </option>
                    <option
                      class="is-color-green"
                      :selected="priorityFilter === 'Low'"
                      value="Low"
                    >
                      {{ $t('low') }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div class="filter-item">
              <div class="columns is-vcentered">
                <span class="lable-inline">Filter: </span>
                <input
                  v-model="textFilter"
                  class="input"
                  type="text"
                  placeholder="Filter value"
                >
              </div>
            </div>
          </div>
          <data-table
            ref="table"
            :items="items"
            sort-by="_id"
            :theme="theme"
            :filter="textFilter"
            :disable-filtering="false"
          >
            <data-column :label="$t('title')">
              <template slot-scope="props">
                <a
                  class="is-text break-text"
                  href="#"
                  @click.prevent="ticketDetail(props.item.id)"
                >
                  <span>{{ props.item.title }}</span>
                </a>
              </template>
            </data-column>
            <data-column
              :label="$t('maintainer')"
              width="20%"
              :sortable="false"
            >
              <template slot-scope="props">
                <div class="is-text is-size-7 info-line">
                  <div class="info-value">
                    <span
                      v-for="e in props.item.maintainer"
                      :key="e"
                      class="is-text is-size-6"
                    >{{ e }}</span>
                  </div>
                </div>
              </template>
            </data-column>
            <data-column :label="$t('createdAt')" width="20%" :sortable="false">
              <template slot-scope="props">
                <div class="is-text is-size-7 info-line">
                  <div class="info-value">
                    <span class="is-size-6">{{
                      props.item.created_at
                        ? format(
                          new Date(props.item.created_at),
                          'dd/MM/yyyy HH:mm:ss'
                        )
                        : 'None'
                    }}</span>
                  </div>
                </div>
              </template>
            </data-column>
            <data-column :label="$t('priority')" width="10%" :sortable="false">
              <template slot-scope="props">
                <div class="is-text is-size-5 info-line">
                  <div class="info-value">
                    <select
                      :class="{
                        'select-setting': true,
                        'is-color-red': props.item.priority === 'Critical',
                        'is-color-yellow': props.item.priority === 'High',
                        'is-color-blue': props.item.priority === 'Medium',
                        'is-color-green': props.item.priority === 'Low'
                      }"
                      @change="changePriority($event, props.item)"
                    >
                      <option
                        class="is-color-red"
                        :selected="props.item.priority === 'Critical'"
                        value="Critical"
                      >
                        {{ $t('critical') }}
                      </option>
                      <option
                        class="is-color-yellow"
                        :selected="props.item.priority === 'High'"
                        value="High"
                      >
                        {{ $t('high') }}
                      </option>
                      <option
                        class="is-color-blue"
                        :selected="props.item.priority === 'Medium'"
                        value="Medium"
                      >
                        {{ $t('medium') }}
                      </option>
                      <option
                        class="is-color-green"
                        :selected="props.item.priority === 'Low'"
                        value="Low"
                      >
                        {{ $t('low') }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
            </data-column>
            <data-column :label="$t('status')" width="10%" :sortable="false">
              <template slot-scope="props">
                <div class="is-text is-size-5 info-line">
                  <div class="info-value">
                    <select
                      :class="{
                        'select-setting': true,
                        'is-color-red': props.item.status === 'Overdue',
                        'is-color-yellow': props.item.status === 'Pending',
                        'is-color-green': props.item.status === 'Closed',
                        'is-color-teal': props.item.status === 'Unassigned'
                      }"
                      @change="changeStatus($event, props.item)"
                    >
                      <option
                        class="is-color-red"
                        :selected="props.item.status === 'Overdue'"
                        value="Overdue"
                      >
                        {{ $t('overdue') }}
                      </option>
                      <option
                        class="is-color-yellow"
                        :selected="props.item.status === 'Pending'"
                        value="Pending"
                      >
                        {{ $t('pending') }}
                      </option>
                      <option
                        class="is-color-teal"
                        :selected="props.item.status === 'Unassigned'"
                        value="Unassigned"
                      >
                        {{ $t('unassigned') }}
                      </option>
                      <option
                        class="is-color-green"
                        :selected="props.item.status === 'Closed'"
                        value="Closed"
                      >
                        {{ $t('closed') }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
            </data-column>
            <data-column :label="$t('settings')" width="15%" :sortable="false">
              <template slot-scope="props">
                <p>
                  <span
                    v-if="props.item.setting.first_alert"
                    class="tag is-success is-light"
                  >{{ $t('alertToMaintainer') }}</span>
                  <span v-else class="tag is-warning is-light">{{
                    $t('dontAlertToMaintainer')
                  }}</span>
                </p>
                <p v-if="props.item.setting.remind">
                  <span
                    class="tag is-default is-light"
                  >{{ $t('deadline') }}:
                    {{
                      props.item.setting.deadline
                        ? format(
                          new Date(props.item.setting.deadline),
                          'dd/MM/yyyy HH:mm:ss'
                        )
                        : 'None'
                    }}</span>
                  <span
                    class="tag is-default is-light"
                  >{{ $t('remindBeforeDeadline') }}:
                    {{ props.item.setting.offset }} Minutes</span>
                </p>
                <p v-else>
                  <span class="tag is-warning is-light">{{
                    $t('dontRemind')
                  }}</span>
                </p>
              </template>
            </data-column>
            <data-column
              :label="$t('actions')"
              class="has-text-centered"
              :sortable="false"
              width="10%"
            >
              <template slot-scope="props">
                <div class="has-text-left">
                  <button
                    class="button is-text"
                    @click.prevent="edit(props.item)"
                  >
                    <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
                  </button>
                  <button
                    class="button is-text"
                    @click.prevent="destroy(props.item)"
                  >
                    <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
                  </button>
                </div>
              </template>
            </data-column>
          </data-table>
        </box>
      </div>
    </div>
    <ticket-viewer v-else :ticket-id="ticketID" />
    <modal v-if="item" :show.sync="modal">
      <box style="min-height: 550px">
        <div slot="header">
          {{ item.title || $t('newTicket') }}
        </div>
        <form @submit.prevent="submit">
          <div class="stepper">
            <div
              :class="{
                'stepper-item': true,
                'stepper-done': stepper > 1,
                'stepper-active': stepper === 1
              }"
              style="width: 50%"
              @click="stepper = 1"
            >
              <span class="steper-header">{{ $t('infomation') }}</span>
            </div>
            <div
              :class="{
                'stepper-item': true,
                'stepper-done': stepper > 2,
                'stepper-active': stepper === 2
              }"
              style="width: 50%"
              @click="stepper = 2"
            >
              <span class="steper-header">{{ $t('assignedTo') }}</span>
            </div>
          </div>
          <transition name="expand">
            <div>
              <div v-show="stepper === 1">
                <div class="field">
                  <label class="label">{{ $t('title') }}</label>
                  <div class="control">
                    <input v-model="item.title" class="input" type="text">
                  </div>
                </div>
                <div class="group-field">
                  <div class="field-2">
                    <label class="label">{{ $t('priority') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.priority"
                          :options="priorityList"
                          style="color: white"
                        />
                      </p>
                    </div>
                  </div>
                  <div class="field-2">
                    <label class="label">{{ $t('maintainer') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="item.maintainer"
                          :options="userList"
                          :multiple="true"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <label class="label">{{ $t('settings') }}</label>
                <div class="group-field-setting">
                  <div class="field-line">
                    <div class="field-2">
                      <input
                        id="first-alert"
                        v-model="item.setting.first_alert"
                        type="checkbox"
                        name="first-alert"
                        class="switch"
                      >
                      <label for="first-alert">{{
                        item.setting.first_alert
                          ? $t('alertToMaintainer')
                          : $t('dontAlertToMaintainer')
                      }}</label>
                    </div>
                    <div class="field-2">
                      <input
                        id="remind"
                        v-model="item.setting.remind"
                        type="checkbox"
                        name="remind"
                        class="switch"
                      >
                      <label for="remind">{{
                        item.setting.remind ? $t('remind') : $t('dontRemind')
                      }}</label>
                    </div>
                  </div>
                  <div v-show="item.setting.remind" class="field-line">
                    <div class="field-2">
                      <label class="label">{{ $t('deadline') }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <date-picker
                            :value.sync="selectedDeadline"
                            time
                            required
                          />
                        </p>
                      </div>
                    </div>
                    <div class="field-2">
                      <label class="label">{{
                        $t('remindBeforeDeadline')
                      }}</label>
                      <div class="field is-grouped">
                        <p class="control is-expanded">
                          <span class="select" style="width: 100%">
                            <select
                              v-model="item.setting.offset"
                              style="width: 100%"
                            >
                              <option selected="true" :value="15">
                                15 Mins
                              </option>
                              <option :value="30">30 Mins</option>
                              <option :value="60">1 Hour</option>
                              <option :value="180">3 Hours</option>
                              <option :value="360">6 Hours</option>
                              <option :value="720">12 Hours</option>
                              <option :value="1440">1 Day</option>
                            </select>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="stepper === 2">
                <div class="group-field">
                  <div class="field-auto" style="width: -webkit-fill-available">
                    <label class="label">{{ $t('assignedTo') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <v-select
                          v-model="ticketconfig.assign"
                          :options="userList"
                          :multiple="true"
                          :taggable="true"
                          :push-tags="true"
                        />
                      </p>
                    </div>
                  </div>
                  <div
                    class="field-auto"
                    style="margin-top: 32px; width: fit-content; height: 34px"
                  >
                    <button
                      id="deadline-btn"
                      class="button"
                      type="button"
                      :class="ticketconfig.remind ? 'is-success' : 'is-default'"
                      title="Set Deadline"
                      @click="ticketconfig.remind = !ticketconfig.remind"
                    >
                      <octicon :icon="clock" :scale="1.5" /><br><span />
                    </button>
                  </div>
                </div>
                <div v-show="ticketconfig.remind" class="field-line">
                  <div class="field-2">
                    <label class="label">{{ $t('deadline') }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <date-picker
                          :value.sync="ticketconfig.deadline"
                          time
                          required
                        />
                      </p>
                    </div>
                  </div>
                  <div class="field-2">
                    <label class="label">{{
                      $t('remindBeforeDeadline')
                    }}</label>
                    <div class="field is-grouped">
                      <p class="control is-expanded">
                        <span class="select" style="width: 100%">
                          <select
                            v-model="ticketconfig.offset"
                            style="width: 100%"
                          >
                            <option selected="true" :value="15">15 Mins</option>
                            <option :value="30">30 Mins</option>
                            <option :value="60">1 Hour</option>
                            <option :value="180">3 Hours</option>
                            <option :value="360">6 Hours</option>
                            <option :value="720">12 Hours</option>
                            <option :value="1440">1 Day</option>
                          </select>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('description') }}</label>
                </div>
                <vue-editor v-model="content" :editor-toolbar="customToolbar" />
                <file-select v-model="attachFiles" />
              </div>
            </div>
          </transition>
        </form>
        <div slot="footer" class="field is-grouped is-grouped-right">
          <div v-show="stepper > 1" class="control">
            <button
              type="button"
              class="button is-primary"
              @click.prevent="stepper--"
            >
              {{ $t('previous') }}
            </button>
          </div>
          <div v-show="stepper < 2" class="control">
            <button
              type="button"
              class="button is-primary"
              @click.prevent="stepper++"
            >
              {{ $t('next') }}
            </button>
          </div>
          <div v-show="stepper === 2" class="control">
            <button type="submit" class="button is-link" @click="submit">
              {{ $t('save') }}
            </button>
          </div>
          <div class="control">
            <button
              type="button"
              class="button is-link is-light"
              @click.prevent="closeModal"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </div>
      </box>
    </modal>
  </section>
</template>
<script>
import { DataTable, DataColumn, Modal, DatePicker } from '@cyradar/ui'
import RangePicker from '@/components/RangePicker'
import StandardChart from '@/components/StandardChart'
import FileSelect from '@/components/FileSelect'
import TicketViewer from '@/components/TicketViewer'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'
import {
  plus,
  x,
  pencil,
  chevronDown,
  chevronUp,
  linkExternal,
  tag,
  play,
  repoClone,
  mail,
  info,
  clock
} from 'octicons-vue'
import { format } from 'date-fns'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    StandardChart,
    DataTable,
    DataColumn,
    Modal,
    vSelect,
    VueEditor,
    FileSelect,
    DatePicker,
    TicketViewer,
    RangePicker
  },
  data () {
    return {
      item: {
        setting: {
          first_alert: true,
          remind: false,
          deadline: new Date()
        },
        ticket_data: []
      },
      ticketconfig: {
        remind: false,
        deadline: new Date(),
        offset: 30,
        assign: []
      },
      range: 'day-30',
      dates: [],
      stepper: 1,
      selectedDeadline: new Date(),
      formData: new FormData(),
      content: '',
      ready: true,
      assigned: [],
      assignedItem: '',
      priorityList: ['Critical', 'High', 'Medium', 'Low'],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block']
      ],
      priorityFilter: '',
      statusFilter: '',
      textFilter: '',
      userNames: [],
      attachFiles: [],
      modal: false,
      timeLineChartready: false,
      vizData: {
        closed: {
          title: 'closed',
          type: 'sum',
          total: 0
        },
        pending: {
          title: 'pending',
          type: 'sum',
          total: 0
        },
        overdue: {
          title: 'overdue',
          type: 'sum',
          total: 0
        },
        unassigned: {
          title: 'unassigned',
          type: 'sum',
          total: 0
        }
      },
      timeLineChart: {}
    }
  },
  computed: {
    ticketID () {
      return this.$route.query.ticketid
    },
    theme () {
      return {
        ...this.$_ui_theme_tables,
        datatable__filter: 'disable-filter'
      }
    },
    clock () {
      return clock
    },
    plus () {
      return plus
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    chevronDown () {
      return chevronDown
    },
    chevronUp () {
      return chevronUp
    },
    linkExternal () {
      return linkExternal
    },
    tag () {
      return tag
    },
    play () {
      return play
    },
    info () {
      return info
    },
    repoClone () {
      return repoClone
    },
    mail () {
      return mail
    },
    userList () {
      return this.userNames.map((c) => c.email)
    },
    user () {
      return this.$store.getters['authentication/user']
    }
  },
  watch: {
    dates () {
      if (
        (this.ticketID && this.ticketID.length > 0) ||
        this.dates.length < 2
      ) {
        return
      }
      this.stats()
      this.$refs.table.loadItems()
    }
  },
  mounted () {
    this.stats()
    this.loadUserName()
  },
  methods: {
    format,
    ticketDetail (id) {
      window.location.href = '/#/tickets?ticketid=' + id
    },
    reloadRangePicker () {
      if (!this.$refs.rangepicker) {
        return
      }

      this.$refs.rangepicker.reloadSuggestions()
    },
    changeFilter () {
      this.$refs.table.loadItems()
    },
    changeStatus (e, itm) {
      itm.status = e.target.value
      this.item = itm
      this.update()
    },
    changePriority (e, itm) {
      itm.priority = e.target.value
      this.item = itm
      this.update()
    },
    closeModal () {
      this.modal = false
      this.modalEvent = false
    },
    submit () {
      this.item.setting.offset = parseInt(this.item.setting.offset)
      this.item.setting.deadline = this.selectedDeadline
      this.item.ticket_data.push({
        processor: this.ticketconfig.assign,
        content: this.content,
        remind: this.ticketconfig.remind,
        deadline: this.ticketconfig.deadline,
        offset: this.ticketconfig.offset
      })
      this.formData = new FormData()

      this.formData.append('data', JSON.stringify(this.item))
      for (var i = 0; i < this.attachFiles.length; i++) {
        this.formData.append('attachfiles', this.attachFiles[i])
      }

      if (this.item.id) {
        this.update()
        return
      }

      this.store()
    },
    edit (item) {
      this.selectedDeadline = new Date(item.setting.deadline)
      this.item = JSON.parse(JSON.stringify(item))
      this.modal = true
    },
    store () {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
      return this.$http
        .post('/api/v1/tickets', this.formData, { headers })
        .then((body) => {
          if (!body || !body.data) {
            return
          }

          this.closeModal()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: body.data.message
          })

          this.$refs.table.loadItems()
        })
    },
    update () {
      return this.$http
        .patch(`/api/v1/tickets/${this.item.id}`, this.item)
        .then((body) => {
          if (!body || !body.data) {
            return
          }

          this.closeModal()
          this.$store.dispatch('NOTIFY', {
            type: 'success',
            text: body.data.message
          })

          this.$refs.table.loadItems()
        })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/tickets/${item.id}`).then((body) => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    },
    items (filter, order, pagination) {
      return this.$http
        .get('/api/v1/tickets', {
          params: {
            priority: this.priorityFilter,
            status: this.statusFilter,
            filter: filter.query,
            start: this.dates[0].getTime(),
            end: this.dates[1].getTime(),
            sort: order.by,
            order: order.order,
            page: pagination.page,
            limit: pagination.perPage
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return {}
          }

          return body.data.data
        })
    },
    create () {
      this.item = {
        setting: {
          first_alert: false,
          remind: false,
          deadline: new Date(),
          offset: 30
        },
        ticket_data: [],
        maintainer: [this.user.id()]
      }
      this.modal = true
    },
    stats () {
      this.vizData.pending.total = 0
      this.vizData.closed.total = 0
      this.vizData.overdue.total = 0
      this.vizData.unassigned.total = 0
      return this.$http
        .get('/api/v1/tickets/stats', {
          params: {
            start: this.dates[0].getTime(),
            end: this.dates[1].getTime()
          }
        })
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          body.data.data.map((c) => {
            if (c._id === 'Pending') {
              this.vizData.pending.total = c.count
              return
            }
            if (c._id === 'Closed') {
              this.vizData.closed.total = c.count
              return
            }
            if (c._id === 'Overdue') {
              this.vizData.overdue.total = c.count
              return
            }
            if (c._id === 'Unassigned') {
              this.vizData.unassigned.total = c.count
            }
          })
        })
    },
    loadUserName () {
      return this.$http
        .get('/api/v1/users')
        .then((body) => {
          if (!body || !body.data) {
            return []
          }

          return body.data.data
        })
        .then((users) => {
          this.userNames = users.items
        })
    },
    validateEmail (email) {
      const re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
</script>
<style lang="scss">
#deadline-btn {
  width: 45px;
  height: 34px;
  padding: 0.2rem;
  padding-left: 0.4rem;
}
.select-button {
  border: unset !important;
}

.status-head {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}

.lable-inline {
  padding: 0.2rem;
}

.select-setting {
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 0.15rem;
}

.disable-filter {
  display: none;
}

.filter-item {
  width: 15%;
  display: inline-block;
  margin-left: 1rem;
}

.vs--single .vs__selected {
  background-color: #f0f0f0;
}

.filter-row {
  width: 100%;
  padding: 0.5rem;
  display: flex;
}

.incidents-table {
  padding: 0.3rem;
}

.incidents-seconds {
  width: 100%;
  display: flex;
}

.info-line {
  margin-bottom: 0.25rem;
  display: flex;
}

.info-lable {
  min-width: 80px;
  padding-top: 0.1rem;
  display: inline-block;
}

.info-value span {
  margin-bottom: 0.1rem;
}

.incidents-timeline {
  width: 75%;
  padding: 0.3rem;
  height: auto;
  display: inline-block;
}

.incidents-sla-element {
  width: 100%;
  height: 50%;
  padding: 0.3rem;
}

.incidents-sla {
  width: 25%;
  height: auto;
  display: inline-block;
}

.incidents-status {
  margin: 0;
  padding: 0.3rem;
  width: 25%;
  display: inline-block;
}
.break-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.range-picker-advanced {
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
}
</style>
