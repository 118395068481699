<template>
  <section class="section">
    <h2 class="title">
      {{ $t('route.playBooks') }}
      <div class="level">
        <div class="level-left subtitle">
          {{ $t('subtitle.playBooks') }}
        </div>
        <div class="level-right">
          <button v-if="!isAdd" class="button is-primary" @click.prevent="create">
            <octicon :icon="plus" /> <span>{{ $t('newPlaybook') }}</span>
          </button>
        </div>
      </div>
    </h2>
    <box v-if="!isAdd && !isEdit && !isView">
      <data-table
        ref="table" :items="items" sort-by="_id"
        :theme="$_ui_theme_tables"
      >
        <data-column :label="$t('title')" width="20%">
          <template slot-scope="props">
            <a class="is-text break-text" href="#" @click.prevent="playbookDetail(props.item.id)">
              <span>{{ props.item.title }}</span>
            </a>
          </template>
        </data-column>
        <data-column field="description" :label="$t('description')" />
        <data-column field="creator.name" :label="$t('creator')" width="15%" />
        <data-column field="created_at" :label="$t('createdAt')" width="15%">
          <template slot-scope="props">
            {{ props.value ? format(new Date(props.value), 'dd/MM/yyyy HH:mm:ss') : 'None' }}
          </template>
        </data-column>
        <data-column
          :label="$t('actions')" class="has-text-centered" :sortable="false"
          width="15%"
        >
          <template slot-scope="props">
            <div class="has-text-centered">
              <button class="button is-text" @click.prevent="edit(props.item.id)">
                <octicon :icon="pencil" /> <span>{{ $t('edit') }}</span>
              </button>
              <button class="button is-text" @click.prevent="destroy(props.item)">
                <octicon :icon="x" /> <span>{{ $t('delete') }}</span>
              </button>
            </div>
          </template>
        </data-column>
      </data-table>
    </box>
    <playbook-viewer v-else />
  </section>
</template>
<script>
import PlaybookViewer from '@/components/PlaybookViewer'
import { DataTable, DataColumn } from '@cyradar/ui'
import { plus, x, pencil } from 'octicons-vue'
import { format } from 'date-fns'

export default {
  components: { PlaybookViewer, DataTable, DataColumn },
  data () {
    return {
      addflag: false
    }
  },
  computed: {
    playbookID () {
      return this.$route.query.id
    },
    isEdit () {
      return this.$route.query.action === 'edit'
    },
    isAdd () {
      return this.$route.query.action === 'add'
    },
    isView () {
      return this.$route.query.action === 'view'
    },
    x () {
      return x
    },
    pencil () {
      return pencil
    },
    plus () {
      return plus
    }
  },
  mounted () {
  },
  methods: {
    format,
    playbookDetail (id) {
      window.location.href = '/#/playbooks?action=view&id=' + id
    },
    create () {
      if (this.isEdit) {
        if (!window.confirm(this.$t('editWarning'))) {
          return
        }
        window.location.href = '/#/playbooks?action=add'
      }
      window.location.href = '/#/playbooks?action=add'
    },
    edit (id) {
      window.location.href = '/#/playbooks?action=edit&id=' + id
    },
    items (filter, order, pagination) {
      return this.$http.get('/api/v1/playbooks', {
        params: {
          filter: filter.query,
          sort: order.by,
          order: order.order,
          page: pagination.page,
          limit: pagination.perPage
        }
      }).then(body => {
        if (!body || !body.data) {
          return {}
        }

        return body.data.data
      })
    },
    destroy (item) {
      if (!window.confirm(this.$t('rus'))) {
        return
      }

      return this.$http.delete(`/api/v1/playbooks/${item.id}`).then(body => {
        if (!body || !body.data) {
          return
        }

        this.$store.dispatch('NOTIFY', {
          type: 'success',
          text: body.data.message
        })

        this.$refs.table.loadItems()
      })
    }
  }
}
</script>
<style lang="scss">
  .playbook-viewer {
    display: flex;
    width: 100%;
  }
  .playbooks-viz {
    display: inline-block;
    width: 85%;
    margin-right: 0.5rem;
  }
  .playbooks-viz .box__body {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
  }
  .actions-viz {
    display: inline-block;
    width: 15%;
  }
  .filter-item {
    width: fit-content;
    display: inline-block;
    margin-left: 1rem;
  }
  .filter-item input {
    margin-left: 0.5rem;
  }
</style>
